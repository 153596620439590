import React from 'react';
import { Card, Grid, Layout, List, Col, Rate, Row } from 'antd';
import companyShowStyles from '../CompanyShow.module.css';
import reqwest from "reqwest";
import dayjs from 'dayjs';
import LoadingSpinner from '../../Shared/LoadingSpinner/LoadingSpinner';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const { Content } = Layout;
const { useState, useEffect } = React;
const { useBreakpoint } = Grid;


export default function CompanyBulletPoints({ company, seoName }) {
    dayjs.extend(utc);
    dayjs.extend(tz);

    const [isLoading, setIsLoading] = useState(false);
    const [bulletPoints, setBulletPoints] = useState([]);

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/companies/` + seoName + '/company_bullet_points',
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            type: "json"
        });

        setBulletPoints(response.bullet_points);
        setIsLoading(false);
    };

    useEffect(() => {
        if (company && seoName) {
            customFetch({});
        }
    }, []);

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
    }

    return (
        <Row justify="center" align="middle" style={{ backgroundColor: '#fff' }}>
            <Col>
                <section className={companyShowStyles.overviewContainer}>
                    <Row>
                        <Col lg={{ span: 20 }}>
                            {isLoading ? <LoadingSpinner /> : <>
                                <h3 className={companyShowStyles.bulletH3}>{company.company_name} Overview</h3>
                                <p className={companyShowStyles.aggregatedReviews}><Rate allowHalf disabled defaultValue={company.aggregate_rating} style={{ color: '#EE9924', fontSize: 25 }} /> <strong>Based on {company.number_of_approved_reviews} aggregated verified reviews.</strong></p>
                            </>}
                        </Col>
                    </Row>
                    <hr className={companyShowStyles.hrStyle} />
                    <Row>
                        <section className={companyShowStyles.bulletPoints}>
                            <List
                                size={'small'}
                                loading={isLoading}
                                grid={{
                                    gutter: 16,
                                    xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2,
                                }}
                                dataSource={bulletPoints}
                                renderItem={(item) => (
                                    <List.Item className='companyBulletPoints'>
                                        <p><span className={companyShowStyles.squareBulletPoint}></span>{item.description}</p>
                                    </List.Item>
                                )}
                            />
                        </section>
                    </Row>
                </section>
            </Col>
            <Col lg={{ span: 5, offset: 1 }}>
                <section className={companyShowStyles.jumpMenu}>

                </section>
            </Col>
        </Row>
    )
}
