import React from 'react';
import CompanyBulletPoints from './children/CompanyBulletPoints';
import CompanyReviews from './children/CompanyReviews';
import reqwest from "reqwest";
import { useParams } from 'react-router-dom';
import { Grid, Col, Layout, Row, Button, Image } from 'antd';
import { useHistory } from 'react-router-dom';
import { PhoneOutlined } from '@ant-design/icons';
import companyShowStyles from './CompanyShow.module.css';
import dayjs from 'dayjs';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import ReviewNew from '../Review/ReviewNew';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const { Header } = Layout;
const { useState, useEffect } = React;
const { useBreakpoint } = Grid;

export default function CompanyShow(props) {
    dayjs.extend(utc);
    dayjs.extend(tz);

    let { seo_name } = useParams();
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState({});

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/companies/` + seo_name,
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            type: "json"
        });
        setCompany(response.company)
        setIsLoading(false);
    };

    const reviewPage = () => {
        history.push(`/car-warranty-reviews/`)
    }

    useEffect(() => {
        customFetch({});
    }, []);

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
    }

    const companyPhoneButton = (company) => {
        if (company.company_name == 'CarShield') {
            return (
                <>
                    <Button className={`${companyShowStyles.callButton}`} icon={<PhoneOutlined />} type='primary' href='tel:800-498-0995'>800-498-0995</Button>
                </>
            )
        } else if (company.company_name == 'CARCHEX') {
            return (
                <>
                    <Button className={`${companyShowStyles.callButton}`} icon={<PhoneOutlined />} type='primary' href='tel:877-253-0058'>877-226-7119</Button>
                </>
            )
        } else {
            return (
                ''
            )
        }
    }

    return (
        <Layout className={companyShowStyles.layoutStyle}>
            <Row justify="center" align="middle" className={companyShowStyles.headerStyle}>
                <Col lg={{ span: 15 }} align='middle' justify='center'>
                    <h1 className={companyShowStyles.headerTitle}>{company.company_name} Reviews</h1>
                    <p className={companyShowStyles.smallParagraph}>Save time researching extended warranty companies. Reviews and ratings were populated from the listed source.</p>
                </Col>
            </Row>
            <Row justify="center" align="middle" >
                <Col lg={{ span: 18 }} xs={{ span: 24 }} style={{ backgroundColor: '#fff' }}>
                    <Row className={companyShowStyles.showContainer} justify='space-between'>
                        <Col xs={{ span: 24 }} lg={{ span: 7 }} align='middle' justify='center'>
                            {isLoading ? <LoadingSpinner /> : <Image style={{ margin: '0 auto 20px' }} preview={false} src={company.logo_svg} alt="company logo" />}
                        </Col>
                        <Col lg={{ span: 15 }} align='middle' justify='center' className={companyShowStyles.companyDescriptionContainer}>
                            <p className={companyShowStyles.companyDescription}>{company.long_description}</p>
                        </Col>
                        {company.logo_svg && seo_name ? <CompanyBulletPoints company={company} seoName={seo_name} /> : <LoadingSpinner />}
                    </Row>
                    <Row justify='space-around' align='middle' style={{ marginBottom: '20px' }}>
                        <Col xs={{ span: 14 }} sm={{ span: 7 }} md={{ span: 7 }}>{companyPhoneButton(company)}</Col>
                    </Row>
                </Col>
            </Row>

            <Row className={companyShowStyles.shareExperience} justify="space-around" align="middle">
                <Col xs={24} md={12}>
                    <h3 className={companyShowStyles.experienceHeadline}>Want to Share your experience?</h3>
                </Col>
                <Col xs={24} md={12} className={companyShowStyles.experienceButton}>
                    {isLoading ? <LoadingSpinner /> : <ReviewNew rankPlacementId={null} companyId={company.id} seoName={company.seo_name} companyLogo={company.logo_svg} />}
                </Col>
            </Row>
            <span style={{ backgroundColor: '#fff' }}>
                {company.logo_svg && seo_name ? <CompanyReviews company={company} seoName={seo_name} /> : <LoadingSpinner />}
            </span>
            <section className={companyShowStyles.footer}>
                <Row>
                    <Col lg={{ span: 12, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Read Extended Car Warranty Reviews</h1>
                    </Col>
                    <Col lg={{ span: 8, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} width={300} src="/assets/images/papers-with-magnifying-glass.png" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ offset: 2 }} xs={{ span: 20, offset: 2 }}><Button onClick={() => reviewPage(company.seo_name)} type="primary" className={companyShowStyles.compareButton}>Read Reviews</Button></Col>
                </Row>
            </section>
        </Layout >
    )
}
