import React from 'react';
import { Col, Image, Row } from 'antd'
import { NavLink } from "react-router-dom";
import globalFooterStyles from './GlobalFooter.module.css';
export default function GlobalFooter() {
    return (
        <section className={globalFooterStyles.globalFooter}>
            <Row>
                <Col lg={{ span: 4 }} xs={{ span: 20, offset: 1 }}>
                    <Image width={350} preview={false} src="/assets/images/warranty-reviews-logo-red-white.png" alt="Warranty Reviews Logo" />
                </Col>
                <Col lg={{ span: 16, offset: 6 }} xs={24}>
                    <Row>
                        <Col lg={24} xs={24}>
                            <span className={globalFooterStyles.navBar}>
                                <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 mb-3">
                                    <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                                        <div
                                            className={
                                                "lg:flex flex-grow items-center"
                                            }
                                        >
                                            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                                                <li className="nav-item">
                                                    <NavLink
                                                        className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:underline hover:underline-offset-2 text-blue-500 "
                                                        activeClassName="active"
                                                        exact
                                                        to="/privacy_policy">
                                                        <span className="ml-2">Privacy Policy</span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink
                                                        className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:underline hover:underline-offset-2 text-blue-500 "
                                                        activeClassName="active"
                                                        exact
                                                        to="/accessbility_statement">
                                                        <span className="ml-2">Accessibility Statement</span>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} xs={{ span: 20, offset: 2 }} className={globalFooterStyles.legalDisclaimer}>
                            <p><small>Disclaimer: WarrantyReviews.com is assembled by WarrantyReviews, LLC. The materials and information on this website are intended for informational purposes only. The reviews are not the thoughts or opinions of WarrantyReviews, LLC. The website may contain links to third party websites as well as third party advertisements. Warranty Reviews is not responsible for and has no liability for the privacy or other practices of any such third party. We recommend that you review the privacy policies of each website you visit.</small></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}
