import React from 'react'
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function AreTherePenaltiesForCanceling() {

    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }
    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Are there penalties for canceling car warranties? | Warranty Reviews</title>
                <meta property="og:title" content="Are there penalties for canceling car warranties? | Warranty Reviews" />
                <meta name="description" content="While you can't transfer a car warranty from one car to another, you can transfer a warranty to a new owner. Learn how." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Are there penalties for canceling car warranties?</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>Are there penalties for canceling car warranties?</span></p></Col>
                </Row>
            </section>

            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>Aside from a home, purchasing a vehicle is the largest expense most people undertake. You want to protect your purchase, so when the dealership suggests an extended car warranty, you agree. But what happens if you decide to cancel this extra protection for your vehicle? Can you get out of a car warranty agreement once you sign it? Will you be financially penalized for canceling an extended car warranty?</p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/car-warranty-chalkboard.jpg'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Canceling Your Extended Car Warranty</h2>
                        <p style={{ lineHeight: pLineHeight }}>Before you cancel your extended car warranty, decide if canceling the coverage is the <Link to={'/car-warranty-faq/are-extended-car-warranties-worth-it'}>right thing</Link> to do or if there's a different type of car warranty that may work better for your circumstances. The good news is most car warranties can be canceled at any time. However, there are some important guidelines to remember:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>It's important to read the fine print on your policy to find out who to contact and if there's a cancellation fee.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Generally speaking, you can cancel the service contract for a new vehicle within 60 days without a penalty. After 60 days, you can still cancel and receive a partial refund.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Be aware that for used cars, the cancellation window on a car warranty drops to 30 days.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>How long do car warranties last?</h3>
                        <p style={{ lineHeight: pLineHeight }}>The extended warranty is a contract between you and the car dealership or manufacturer that is responsible for covering auto repairs related to defects caused by design or installation within a specific period of time or amount of mileage. For a new car, warranty coverage can last anywhere from two to seven years or until you reach a specified mileage cap (often three years and 36,000 miles).</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 style={{ lineHeight: h2LineHeight }}>What if the car warranty coverage was written into my auto loan?</h3>
                        <p style={{ lineHeight: pLineHeight }}>If the warranty was written into your auto loan, canceling it won't change the amount of your monthly payment. However, it could mean that you pay off your car or truck sooner once the refund is deducted from your loan balance. A good place to start is calling the finance manager who sold it to you. For third party warranties, you may need to call or write a letter to the company, so check your loan paperwork closely. </p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Tips about transferring your car warranty</h2>
                        <p style={{ lineHeight: pLineHeight }}>Many sellers mention any included warranties when marketing their vehicles. Pay close attention to the <strong>type</strong> of warranty that is transferring to the vehicle you purchase.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Warranty Reimbursement Process</h3>
                        <p style={{ lineHeight: pLineHeight }}>Every car warranty company is different, but it could take three to six weeks, sometimes longer, to receive a refund on the unused portion of your car warranty agreement. When you call the warranty provider, don't be surprised if they send you to a “retention” department to try and talk you out of it. Just say no. Be firm about wanting to cancel and ask them when you can expect to receive your refund. </p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Will you get your money back if you cancel your policy after you've owned the vehicle for 60 days?</h3>
                        <p style={{ lineHeight: pLineHeight }}>You can request a refund of the unused portion of your policy from your dealership. Alternatively, you can cancel directly with the car warranty company and get a prorated amount refunded. Either way, be sure to save a copy of the signed cancellation confirmation for your records.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>How Selling Your Car Affects Your Car Warranty</h2>
                        <p style={{ lineHeight: pLineHeight }}>If you decide to sell your vehicle before the extended warranty expires, the warranty typically transfers to the new owner. However, you can absolutely cancel the policy if you choose, and you should be able to get a prorated refund from your lender or from the company that sold you the warranty coverage. But it's important to note that vehicles with extended warranties often sell for more and much faster.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Can your car warranty transfer to a new car? </h2>
                        <p style={{ lineHeight: pLineHeight }}>Warranties are never transferred from vehicle to vehicle — they stay paired with their assigned vehicle. On the off chance the person buying your vehicle doesn't want to take over your extended coverage policy, be sure to reach out to the warranty company as soon as possible to cancel the coverage. You may be entitled to a partial refund! </p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Car Warranty Protection</h2>
                        <p style={{ lineHeight: pLineHeight }}>A car warranty provides peace of mind. Although they do not cover fender benders or normal wear and tear, car warranties can save you money on repairs for the mechanical breakdown of parts, faulty design, or improper installation. That includes everything from brakes to steering and even heating and air conditioning. Expanded <Link to={'/car-warranty-faq/what-do-car-warranties-cover'} >'bumper to bumper' car warranties</Link> cover nearly every component of a vehicle, paying for the repair or replacement of any defective item until your car reaches a specific age or mileage. Read your policy closely because it may even offer roadside assistance for tows, lockouts, and more!</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Protect Yourself Against Untrustworthy Car Warranty Service Providers</h2>
                        <p style={{ lineHeight: pLineHeight }}>How do you know what extended car warranty companies are trustworthy? Warranty Reviews gives you the resources to browse reviews from actual customers and explore the features of the best warranty companies. <Link to={'/car-warranty-reviews'}>Compare warranty companies</Link> to find the right plan for you.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
