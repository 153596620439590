import React from 'react';
import AdminNavBar from './AdminNavBar';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import AdminReviews from '../AdminReviews/AdminReviews';
import AdminOther from '../AdminOther/AdminOther';
import AdminGetQuotes from '../AdminGetQuotes/AdminGetQuotes';
import AdminCcpaRequests from '../AdminCcpaRequests/AdminCcpaRequests';
import AdminOptOutRequests from '../AdminOptOutRequests/AdminOptOutRequests';

export default function Admin() {
    let { path, url } = useRouteMatch();


    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <div>
                        <h3>Please select an option from the menu.</h3>
                    </div>
                </Route>
                <Route path={`${path}/admin-reviews`}>
                    <AdminReviews />
                </Route>
                <Route path={`${path}/admin-quotes`}>
                    <AdminGetQuotes />
                </Route>
                <Route path={`${path}/admin-ccpa-requests`}>
                    <AdminCcpaRequests />
                </Route>
                <Route path={`${path}/admin-opt-out-requests`}>
                    <AdminOptOutRequests />
                </Route>
                <Route path={`${path}/admin-other`}>
                    <AdminOther />
                </Route>
            </Switch>
        </>
    )
}
