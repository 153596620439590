import React from 'react';
import reqwest from "reqwest";
import { useHistory, useLocation } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import homeStyles from './Home.module.css';
import { Button, Col, Grid, Image, Layout, Row } from 'antd';
import { loginStore } from '../../context/Store';
import CompanyCard from './children/CompanyCard';
import CarWarrantyModal from './children/CarWarrantyModal';

const { useState, useEffect } = React;
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function Home() {
    const [companyList, setCompanyList] = useState([]);
    const [componantList, setComponantList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const showLogin = loginStore(state => state.toggle);

    let history = useHistory();
    const location = useLocation();

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/companies`,
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            type: "json"
        });

        if (response.companies.length > 0) {

            setCompanyList(response.companies);

            let list = response.companies;

            setComponantList(list.map((company, index) => {
                return (
                    <CompanyCard key={company.id.toString()} index={index} company={company} isLoading={isLoading} />
                )
            }));
        } else {
            // If there is no companyList, map the response to an array of CompanyCard Components

        }

        setIsLoading(false);
    };

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }

    const writeReviewPage = (id) => {
        history.push(`/company/${id}/review/new`)
    }


    const howDoCarWarrantiesWork = () => {
        history.push(`/car-warranty-faq/how-do-car-warranties-work`)
    }

    const howToChooseCarWarranty = () => {
        history.push(`/car-warranty-faq/how-to-choose-car-warranty`)
    }

    const whatDoCarWarrantiesCover = () => {
        history.push(`/car-warranty-faq/what-do-car-warranties-cover`)
    }

    useEffect(() => {
        console.log(location.state)
        customFetch({});
        if (location.state && location.state.showLogin == true) {
            showLogin();
        };
    }, []);

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginBottom = '';
    let marginTop = '';
    let pFontSize = '';
    let pSubHeadingFontSize = '';
    let pSubHeadingLineHeight = '';
    let pLineHeight = '';
    let h1FontSize = '';
    let h1LineHeight = '';
    let h1SubHeadingFontSize = '';
    let h1SubHeadingLineHeight = '';
    let h2FontSize = '';
    let h2LineHeight = '';
    let h3LineHeight = '';
    let backgroundHeight = '';

    if (xs) {
        backgroundHeight = '130%';
        fontSize = '2.188rem';
        h1FontSize = '2.0rem';
        h1LineHeight = '2.625rem';
        h1SubHeadingFontSize = '1.7625rem';
        h1SubHeadingLineHeight = '2.475rem';
        h2FontSize = '2.03rem';
        h2LineHeight = '2.436rem';
        h3LineHeight = '1.5rem';
        lineHeight = '3rem';
        marginBottom = '60px';
        marginTop = '10px';
        pFontSize = '1.005rem';
        pLineHeight = '1.65rem';
        pSubHeadingFontSize = '1.105rem';
        pSubHeadingLineHeight = '1.65rem';
    }

    if (sm) {
        backgroundHeight = '130%';
        fontSize = '2.488rem';
        h1FontSize = '3.516rem';
        h1LineHeight = '3.625rem';
        h1SubHeadingFontSize = '2.0625rem';
        h1SubHeadingLineHeight = '2.475rem';
        h2FontSize = '2.03rem';
        h2LineHeight = '2.436rem';
        h3LineHeight = '1.5rem';
        lineHeight = '3rem';
        marginBottom = '20px';
        marginTop = '20px';
        pFontSize = '1.375rem';
        pLineHeight = '1.65rem';
        pSubHeadingFontSize = '1.375rem';
        pSubHeadingLineHeight = '1.65rem';
    }

    if (md) {
        backgroundHeight = '100%';
        fontSize = '2.5rem';
        h1FontSize = '3.516rem';
        h1LineHeight = '3.625rem';
        h1SubHeadingFontSize = '2.0625rem';
        h1SubHeadingLineHeight = '2.475rem';
        h2FontSize = '3.429rem';
        h2LineHeight = '3.6rem';
        h3LineHeight = '1.5rem';
        lineHeight = '3rem';
        marginBottom = '20px';
        marginTop = '0px';
        pFontSize = '1.375rem';
        pLineHeight = '1.65rem';
        pSubHeadingFontSize = '1.375rem';
        pSubHeadingLineHeight = '1.65rem';
    }

    if (lg) {
        backgroundHeight = '100%';
        fontSize = '3.125rem';
        h1FontSize = '4.688rem';
        h1LineHeight = '5.625rem';
        h1SubHeadingFontSize = '2.0625rem';
        h1SubHeadingLineHeight = '2.475rem';
        h2FontSize = '3.429rem';
        h2LineHeight = '3.6rem';
        h3LineHeight = '1.5rem';
        lineHeight = '3.125rem';
        marginBottom = '20px';
        marginTop = '0px';
        pFontSize = '1.375rem';
        pLineHeight = '1.65rem';
        pSubHeadingFontSize = '1.375rem';
        pSubHeadingLineHeight = '1.65rem';
    }


    return (
        <Layout className={homeStyles.layoutStyle} style={{ minHeight: "100vh" }}>
            <MetaTags>
                <title>Car Warranty Company Reviews | Warranty Reviews</title>
                <meta property="og:title" content="Car Warranty Company Reviews | Warranty Reviews" />
                <meta name="description" content="Not all extended car warranties are the same. Compare car warranty companies and find the plan that is right for you. Get an online quote today." />
            </MetaTags>
            <section className={homeStyles.superHeader}>
                <section className={homeStyles.subHeader} style={{ backgroundHeight: backgroundHeight }}>
                    <Row justify="center" align="middle">
                        <Col lg={{ span: 20 }} xs={{ span: 19 }}>
                            <h1 style={{ fontSize: h1FontSize, lineHeight: h1LineHeight }}>Car Warranty Company reviews</h1>
                            <CarWarrantyModal />
                        </Col>
                    </Row>
                    <Row justify="center" align="middle">
                        <Col>
                            <Button onClick={() => reviewPage()} type="primary" className={homeStyles.compareButton}>Compare Warranty Companies</Button>
                        </Col>
                    </Row>
                </section>
            </section>
            <Content>
                {/* CompanyCard List. */}
                <Row className='mb-12' style={{ marginTop: '40px', marginBottom: '0' }} justify='center'>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }} >
                        <Row className={homeStyles.featuredCompanies} justify='center'>
                            {(componantList.length > 0 ? componantList : null)}
                        </Row>
                    </Col>
                </Row>
                {/* END Top Three Car Warranty Companies based on Reviews. */}
                <Row>
                    <Col lg={{ span: 16, offset: 4 }} xs={{ span: 22, offset: 1 }} justify="center" className={homeStyles.middleHero}>
                        <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>There's no better way to learn about what a company offers than to hear directly from past and current customers. These are curated reviews and comments from those with first-hand experience.</p>
                        <Button onClick={() => reviewPage()} type="primary" className={homeStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
                <Row align="middle" className={homeStyles.contentAreaArticles} >
                    <Col lg={{ span: 8, offset: 2 }}
                        xs={{ span: 22, offset: 1, order: 1 }}
                        md={{ order: 0 }}
                        style={{ marginTop: marginTop, marginBottom: '40px' }}
                        className={homeStyles.textContent}>
                        <h1 className='subHead' style={{ fontSize: h1SubHeadingFontSize, lineHeight: h1SubHeadingLineHeight }}>Learn About Extended Car Warranties</h1>
                        <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>Car warranties protect owners from financial unknows. While no one can predict when parts in your car will stop working, an extended warranty can relieve stress by covering the costs to get you back in the driver’s seat.</p>
                    </Col>
                    <Col lg={{ span: 12, offset: 2 }}
                        xs={{ span: 24, order: 0, marginBottom: '0' }}
                        md={{ order: 1 }}
                    >
                        <Image preview={false} src="/assets/images/red_car_under_hands.png" />
                    </Col>
                </Row>
                <Row justify="center" className={[homeStyles.bulletPoints, homeStyles.contentArea]}>
                    <Col lg={{ span: 7 }} className={homeStyles.column}>
                        <Row>
                            <Col style={{ margin: '0 auto' }}>
                                <div style={{ margin: '0 auto' }} className={homeStyles.circle}>1</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 24 }}>
                                <h3>Research and Purchase</h3>
                                <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>a plan then wait for the plan to go into effect after a waiting period.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={7} className={homeStyles.column}>
                        <Row>
                            <Col style={{ margin: '0 auto' }}>
                                <div style={{ margin: '0 auto' }} className={homeStyles.circle}>2</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 24 }}>
                                <h3>When an issue occurs</h3>
                                <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>with your car, call roadside assistance or take it to a shop to be fixed.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={7} className={homeStyles.column}>
                        <Row>
                            <Col style={{ margin: '0 auto' }}>
                                <div style={{ margin: '0 auto' }} className={homeStyles.circle}>3</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 24 }}>
                                <h3>File a claim</h3>
                                <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>and rest easy knowing the warranty company will pay for the repairs.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={homeStyles.contentArea} justify="center" align="middle">
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                        <Button type="primary" size='large' onClick={() => howDoCarWarrantiesWork()} className={homeStyles.compareButton}>Discover How Warranties Work</Button>
                    </Col>
                </Row>
                <Row align="middle" className={homeStyles.contentAreaArticles}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12, offset: 0 }}>
                        <Image preview={false} src="/assets/images/woman_with_car.png" />
                    </Col>
                    <Col xs={{ span: 22, offset: 1 }} md={{ span: 9, offset: 3 }} lg={{ span: 8, offset: 3 }} xl={{ span: 10, offset: 1 }} className={homeStyles.textContent} style={{ marginTop: marginTop }}>
                        <h1 className='subHead' style={{ fontSize: h1SubHeadingFontSize, lineHeight: h1SubHeadingLineHeight }}>What to Look for When Choosing a Car Warranty Company</h1>
                        <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>Be an informed consumer. What looks great at first glance might not always be the case. Researching policies, reading reviews, and asking the right questions will make the decision-making process smoother and instill confidence in your decision.</p>
                        <Button type="primary" onClick={() => howToChooseCarWarranty()} className={homeStyles.compareButton}>How to Choose a Company</Button>
                    </Col>
                </Row>
                <Row align="middle" className={homeStyles.contentAreaArticles}>
                    <Col
                        xs={{ span: 22, offset: 1, order: 1 }}
                        md={{ span: 9, order: 0 }}
                        lg={{ span: 9 }}
                        xl={{ span: 9, offset: 1 }} className={homeStyles.textContent} style={{ marginTop: marginTop }}>
                        <h1 className='subHead' style={{ fontSize: h1SubHeadingFontSize, lineHeight: h1SubHeadingLineHeight }}>Understanding What Extended Warranties Cover</h1>
                        <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>As you examine which warranties are best for you, it is a good idea to understand the terms of the various plans. Most auto warranty companies offer different rates based on their level of coverage. Whether you are looking for basic coverage on parts for a used vehicle, or to add to the manufacturer’s full coverage for your new car, there is a plan that meets your needs.</p>
                        <Button type="primary" onClick={() => whatDoCarWarrantiesCover()} className={homeStyles.compareButton}>Review What Car Warranties Cover</Button>
                    </Col>
                    <Col xs={{ span: 24, order: 0 }}
                        md={{ span: 12, offset: 2, order: 1 }}
                        lg={{ span: 12, offset: 2 }}
                        xl={{ span: 12, offset: 2 }} style={{ marginTop: marginTop }}>
                        <Image preview={false} src="/assets/images/two_cars_on_table.png" />
                    </Col>
                </Row>
                <Row align="middle" className={homeStyles.contentAreaFaq} justify="center" >
                    <Col lg={{ span: 8 }} xs={{ span: 20, offset: 2 }} style={{ marginTop: marginTop }}>
                        <Image preview={false} src="/assets/images/faq.png" />
                        <h1 className='subHead' style={{ fontSize: h1SubHeadingFontSize, lineHeight: h1SubHeadingLineHeight }}>Frequently Asked Questions</h1>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 23, offset: 1 }} className={homeStyles.textContent}>
                        <p style={{ fontSize: pFontSize, lineHeight: pLineHeight }}>Car warranties can be complicated. We've compiled resources and answers to some some of the most common frequently asked questions.</p>
                        <Button type="primary" onClick={() => whatDoCarWarrantiesCover()} className={homeStyles.compareButton}>View all Resources</Button>
                    </Col>

                </Row>
                <section className={homeStyles.footer}>
                    <Row>
                        <Col lg={{ span: 12, offset: 2 }} xs={{ span: 22, offset: 2 }}>
                            <h1 style={{ fontSize: h1FontSize, lineHeight: h1LineHeight }}>Read Extended Car Warranty Reviews</h1>
                        </Col>
                        <Col lg={{ span: 8, offset: 2 }} xs={{ span: 23 }} >
                            <Image preview={false} width={300} src="/assets/images/papers-with-magnifying-glass.png" />
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={2}>
                            <Button onClick={() => reviewPage()} type="primary" className={homeStyles.compareButton}>Read Reviews</Button>
                        </Col>
                    </Row>
                </section>
            </Content>
        </Layout >
    )
}
