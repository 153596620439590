import React from 'react'
import { NavLink } from "react-router-dom";
import useToken from '../useToken';
import { userStore } from '../Providers/UserStore';
import { addUser, logoutUser } from '../Providers/UserReducer';
import useStickyState from '../Shared/UseStickyState';
import navStyles from './NavBar.module.css'
import { Button, Grid, Image, notification } from 'antd';
import GetQuoteForm from '../GetQuoteForm/GetQuoteForm';
import { useHistory } from "react-router-dom";
import { useStore } from '../../context/Store';
import Login from '../Login/Login';
const { useState, useEffect } = React;
const { useBreakpoint } = Grid;


export default function NavBar(props) {
    const [state] = userStore();
    const { user } = state;
    const [sticky_user, setUser] = useStickyState(user, 'UserObject');
    const [, dispatch] = userStore();
    const toggle = useStore((state) => state.toggle);
    const [visible, setVisible] = useState(false);

    const [showNavbar, setShowNavbar] = React.useState(false);

    const Hamburger = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="24"
            viewBox="0 0 52 24"
        >
            <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="42"
                    height="4"
                    rx="2"
                    transform="translate(304 47)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="42"
                    height="4"
                    rx="2"
                    transform="translate(304 67)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="52"
                    height="4"
                    rx="2"
                    transform="translate(294 57)"
                    fill="#fff"
                />
            </g>
        </svg>
    );

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    let history = useHistory();

    // Remove user from local storage and store
    //
    const logOutUser = () => {
        localStorage.removeItem("access-token");
        localStorage.removeItem("uid");
        localStorage.removeItem("client");
        localStorage.removeItem("UserObject");
        notification.success({
            message: 'Logged out successfully',
            description: 'You are now logged out.',
        });
        setUser('user', null);
        history.push("/");
    }

    const { xs, sm, md, lg } = useBreakpoint();

    let logoWidth = '';
    let marginTop = '';

    if (xs) {
        logoWidth = '150px';
        marginTop = '15px';
    }

    if (sm) {
        logoWidth = '250px';
        marginTop = '0';
    }

    if (md) {
        logoWidth = '250px';
        marginTop = '0';
    }

    if (lg) {
        logoWidth = '250px';
        marginTop = '0';
    }

    return (
        <>

            <nav className="navbar" style={{ position: 'sticky', top: '0px', zIndex: '999' }}>
                <div className="container">
                    <div className={navStyles.logo}>
                        <NavLink exact to="/"><Image style={{ width: logoWidth }} preview={false} src="/assets/images/warranty-reviews-logo-red-white.png" alt="Warranty Reviews Logo" /></NavLink>
                    </div>
                    <div className={navStyles.getQuoteButton}><GetQuoteForm /></div>
                    <div className="menu-icon" onClick={handleShowNavbar}>
                        <Hamburger />
                    </div>
                    <div className={`nav-elements  ${showNavbar && "active"}`}>
                        <ul>
                            <li>
                                <NavLink
                                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                                    activeClassName="active"
                                    exact
                                    onClick={() => handleShowNavbar()}
                                    to="/">
                                    <span className="ml-2">Home</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                                    activeClassName="active"
                                    exact
                                    onClick={() => handleShowNavbar()}
                                    to="/car-warranty-reviews">
                                    <span className="ml-2">Read Reviews</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                                    activeClassName="active"
                                    exact
                                    onClick={() => handleShowNavbar()}
                                    to="/car-warranty-faq">
                                    <span className="ml-2">Resources</span>
                                </NavLink>
                            </li>
                            {sticky_user.user == null &&
                                <li>
                                    <Login />
                                </li>
                            }

                            {(sticky_user.user != null && sticky_user.user.user_role == 'superadmin') &&
                                <li>
                                    <NavLink
                                        className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                                        activeClassName="active"
                                        exact
                                        onClick={() => handleShowNavbar()}
                                        to="/admin/admin-reviews">
                                        <span className="ml-2">Admin Reviews</span>
                                    </NavLink>
                                </li>
                            }

                            {sticky_user.user != null &&
                                <li>
                                    <Button style={{ backgroundColor: '#C42E1C', color: 'white' }} className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 ml-3" type="danger" onClick={() => logOutUser()}>Logout</Button>
                                </li>
                            }

                            <li>
                                <GetQuoteForm />
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


        </>
    )
}
