import React from 'react'
import { Table, Input, Button, Space, TableProps, Layout, Col, Row } from 'antd';
import { SearchOutlined, EditOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, LinkProps } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import adminRateQuoteTableStyle from './AdminRateQuoteTable.module.css';
const { Header, Footer, Sider, Content } = Layout;
export default function AdminRateQuotesTable(props) {
    dayjs.extend(utc);
    dayjs.extend(tz);

    const { rateQuotes } = props;

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Quote ID',
            dataIndex: 'quote_id',
            key: 'quote_id',
            width: '5%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Quote ID"
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }
                            } />
                        <Space>
                            <Button
                                onClick={() => { confirm() }}
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}
                                type="danger"
                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.quote_id.toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: 'Year of Car',
            dataIndex: 'year_of_car',
            key: 'year_of_car',
            width: '6%',
            sorter: (a, b) => (a.year_of_car > b.year_of_car ? 1 : -1),
        },
        {
            title: 'Make of Car',
            dataIndex: 'make_of_car',
            key: 'make_of_car',
            width: '8%',
            sorter: (a, b) => (a.make_of_car > b.make_of_car ? 1 : -1)
        },
        {
            title: 'Model of Car',
            dataIndex: 'model_of_car',
            key: 'model_of_car',
            width: '8%',
            sorter: (a, b) => (a.model_of_car > b.model_of_car ? 1 : -1)
        },
        {
            title: 'Current Mileage',
            dataIndex: 'current_mileage',
            key: 'current_mileage',
            width: '8%',
            sorter: (a, b) => (a.current_mileage > b.current_mileage ? 1 : -1)
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            width: '8%',
            sorter: (a, b) => (a.first_name > b.first_name ? 1 : -1)
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            width: '8%',
            sorter: (a, b) => (a.last_name > b.last_name ? 1 : -1)
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
            width: '8%',
            sorter: (a, b) => (a.phone_number > b.phone_number ? 1 : -1)
        },
        {
            title: 'Email Address',
            dataIndex: 'email_address',
            key: 'email_address',
            width: '8%',
            sorter: (a, b) => (a.email_address > b.email_address ? 1 : -1)
        },
        {
            title: 'Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '10%',
            sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
            render: (id, record) => {
                return (
                    dayjs(record.created_at).tz('America/Chicago').format('MM/DD/YYYY hh:mm:ss A') + ' CST'
                )
            },
        }
    ]

    return (
        <Layout className={adminRateQuoteTableStyle.layoutStyle}>
            <Header className={adminRateQuoteTableStyle.header}>
                <Row>
                    <Col span={12}>
                        <h3>Approve or Deny Reviews</h3>
                    </Col>
                </Row>
            </Header>
            <Content className={adminRateQuoteTableStyle.contentWrapper}>
                <Table
                    style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                    columns={columns}
                    dataSource={rateQuotes}
                    rowKey="id"
                />
            </Content>
        </Layout>
    )
}
