import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;

const { useBreakpoint } = Grid;

export default function HowDoCarWarrantiesWork() {
    let history = useHistory();
    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>How Do Extended Car Warranties Work? | Warranty Reviews</title>
                <meta property="og:title" content="How Do Extended Car Warranties Work? | Warranty Reviews" />
                <meta name="description" content="You can't predict when parts in your car will stop working. An extended car warranty protects you from the unknown and can cover the cost of future repairs. Learn more." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>How Do Car<br /> Warranties Work</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>How do Car Warranties Work</span></p></Col>
                </Row>
            </section>
            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>Car warranties protect owners from financial unknowns. While no one can predict when parts in your car will stop working,
                            an extended warranty can relieve stress by covering
                            the costs to get you back in the driver's seat.</p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/man-working-on-car.jpg'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <p style={{ lineHeight: pLineHeight }}>Purchasing a new car has its perks, including a factory warranty. That is because if something should go wrong, the cost of repairs will be covered. But it is important to note that factory warranties expire. After their expiration date, many auto owners continue their coverage through an extended vehicle protection plan.
                        </p>
                        <p style={{ lineHeight: pLineHeight }}>Regrettably, many people looking to select the right car warranty find themselves swimming into a competitive market. Filled with a flood of information, these waters can be tricky to navigate. That is why sites like warrantyreviews.com are so important.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Choosing a Car Warranty: What to Know</h2>
                        <p style={{ lineHeight: pLineHeight }}>Automobile warranties work like insurance plans. If a problem arises, the car is taken to a mechanic. If there is a need for repairs, check the terms of your warranty. Once coverage is approved, the repairs are made and paid by the warranty company, even if the bill for fixing the car runs into the thousands. There are a few things to know:
                        </p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>There is always a waiting period before the warranty kicks in. Since there is typically no inspection on the vehicles, the waiting period lasts for several days and/or a set number of miles.</p>
                            </li>
                            <li><p style={{ lineHeight: pLineHeight }}>Somewhere down the road, if you have a problem with your car, you can call roadside assistance or take your car to a repair shop. When that happens, either the repair shop or the car owner will file a claim with the warranty company.</p>
                            </li>
                            <li><p style={{ lineHeight: pLineHeight }}>After the claim has been filed, investigated, and approved, funds are paid either directly to the repair shop or paid as a reimbursement to the policy owner. Depending on your plan, some repairs will require a deductible.</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Warranty Coverage Levels</h2>
                        <p style={{ lineHeight: pLineHeight }}>Making an unplanned trip to the mechanic is never fun. There's plenty of anxiety, paperwork, and lots of information to process before repair work can even begin. One way to make your visit go more smoothly is to know if your vehicle is still under warranty and have the needed level of coverage. </p>
                        <p style={{ lineHeight: pLineHeight }}>Depending on the make, model, and manufacturer year of your car, most extended warranty companies offer varying levels of service plans. These plans can cover everything from fuel systems and brakes to the powertrain and computer systems. Many plans also include 24/7 roadside assistance and rental car benefits to help while your car is being repaired. </p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>The Difference Between Car Warranties and Car Insurance</h2>
                        <p style={{ lineHeight: pLineHeight }}>Finding coverage can be confusing if you don't know the difference between warranties and car insurance. Here is some clarification:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Both car warranties and car insurance give owners protection from unexpected issues.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>A major difference between the two is that car insurance is required by law, while car warranties are not.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Another big distinction is that auto insurance will not pay for the mechanical and/or electrical issues you may have with your car. Auto Warranty providers will, however.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Car Warranties: You Have a Choice</h2>
                        <p style={{ lineHeight: pLineHeight }}>Understanding how car warranties work is essential for protecting your automobile and it is an important step to finding the right provider that suits your needs. Conducting research, shopping around, and debunking myths are the best methods for helping consumers learn the ins and outs of buying a car warranty.</p>
                        <p style={{ lineHeight: pLineHeight }}>Save time researching the companies. Explore WarrantyReviews.com to read reviews and learn more information about some of the leading companies.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
