import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function AreCarWarrantiesWorthIt() {
    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }
    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Are Extended Car Warranties Worth It? | Warranty Reviews</title>
                <meta property="og:title" content="Are Extended Car Warranties Worth It? | Warranty Reviews" />
                <meta name="description" content="Having an extended car warranty can mean the difference between saving money or skimping on car repairs, but it's important to choose the right warranty for your needs." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Are Car Warranties <br />Worth It</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>Are Car Warranties Worth It</span></p></Col>
                </Row>
            </section>

            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>All cars need repairs over time. As things break down the costs for repairs rise, auto owners are left with hefty bills.
                            That's why having a car warranty can mean the difference between saving money or skimping on repairs. </p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/office-work.jpg'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <p style={{ lineHeight: pLineHeight }}>At a time when many consumers often mistrust the companies they do business with, car warranty programs don’t get the credit they deserve. Bought more out of necessity than immediate need, car warranties are often an “out of sight, out of mind” investment that is best used in an emergency. So you may be asking yourself, “Are car warranties worth it?”</p>
                        <p style={{ lineHeight: pLineHeight }}>The short answer? It depends!</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>What is a Car Warranty?</h2>
                        <p style={{ lineHeight: pLineHeight }}>A car warranty covers drivers from defective parts, damages caused by the manufacturer’s faulty design or improper installation, or mechanical breakdown of parts. Car warranties do not cover fender bender accidents or routine maintenance costs from normal wear and tear.</p>
                        <p style={{ lineHeight: pLineHeight }}>Manufacturer or factory car warranties come directly from the vehicle manufacturer and last for a specific period of time or number of miles after the initial purchase of the vehicle.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 style={{ lineHeight: h2LineHeight }}>What is an Extended Warranty?</h2>
                        <p style={{ lineHeight: pLineHeight }}>Because car warranty plans differ in the length of coverage, many auto owners take out extended plans once their manufacturer’s policy expires and can differ in several ways. For one, an extended warranty may require a deductible for the consumer. Normally, coverage includes repairs for parts, such as the alternator, air conditioning, engine, transmission, and electrical parts.</p>
                        <p style={{ lineHeight: pLineHeight }}>Extended warranty plans protect owners from unexpected bills and extend their service beyond the basic policy covered by the original factory warranty. Many carriers also feature reasonable plans that can be customized and tailored to meet various budgets.</p>
                        <p style={{ lineHeight: pLineHeight }}>They also feature several options to pay for protection. Most car owners opt for a simple monthly payment plan.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Benefits of Car Warranties</h2>
                        <p style={{ lineHeight: pLineHeight }}>Car warranties benefit drivers by giving them choices that fit their income. They also help cut down on stress so you can stay on the road without worrying about unplanned mechanical repairs.</p>
                        <p style={{ lineHeight: pLineHeight }}>Additional benefits include:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Comprehensive plans can fix or replace expensive computers and electrical gadgets, such as keyless ignitions, built-in GPS and audio options</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>You can take your car anywhere to get fixed because almost all licensed repair shops accept car warranties.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Extended car warranties can be used to cover both new and used vehicles.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>If you sell your vehicle, the plans are transferable to the new car owner. Vehicles with plans often sell for more and much faster!</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Car Warranties Are Worth It</h2>
                        <p style={{ lineHeight: pLineHeight }}>Because car warranties are designed for long-term coverage, if you are only going to keep your car for a few months, it is probably worth the risk of not having a warranty.</p>
                        <p style={{ lineHeight: pLineHeight }}>However, if you are going to keep your car for a while, the security, savings, and peace of mind are worth the expense. Purchasing one is recommended because it will protect car owners from the unexpected. While no one can predict when your car will break down, or if you will be in an accident, a good warranty can relieve stress about the future.</p>
                        <p style={{ lineHeight: pLineHeight }}>The key to getting the most for your car warranty is research. After comparing companies and reading reviews, pick the best car warranty company and coverage plan for your needs.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
