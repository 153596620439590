import React, { useState } from 'react';
import NavBar from '../Shared/NavBar';
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Layout, Modal, notification, Row } from 'antd';
import './Login.css';
import { userStore } from '../Providers/UserStore';
import { addUser } from '../Providers/UserReducer';
import { Link } from 'react-router-dom';
import { loginStore } from '../../context/Store';
import useStickyState from '../Shared/UseStickyState';

export default function Login({ setToken, textLink = false }) {
    // const showForm = loginStore(state => state.toggled);
    const [loginFormData, setLoginFormData] = useState({
        email: '',
        password: '',
    });
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    let history = useHistory();
    const [, dispatch] = userStore();
    const [form] = Form.useForm();
    const onFieldsChange = (changedFields, allFields) => {
        console.log(changedFields)
        changedFields.map((field) => {
            setLoginFormData({ ...loginFormData, [field.name[0]]: field.value })
        })
    }
    const [state] = userStore();
    const { user } = state;
    const [sticky_user, setUser] = useStickyState(user, 'UserObject');

    // const [visible, setVisible] = useState(false);
    const visible = loginStore(state => state.toggled);
    const showLogin = loginStore(state => state.toggle);

    const navigateToCreateAccount = () => {
        showLogin(false);
        history.push("/create-account");
    }

    const layout = {
        layout: 'horizontal',
        wrapperCol: {
            span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
            span: 20,
        },
    };

    const loginUser = async (credentials) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/sign_in`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        })
            .then((res) => {
                if (res.ok) {
                    localStorage.setItem("access-token", res.headers.get("access-token"));
                    localStorage.setItem("uid", res.headers.get("uid"));
                    localStorage.setItem("client", res.headers.get("client"));
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                dispatch(addUser(json));
                switch (json['user']['user_role']) {
                    case 'superadmin':
                        history.push("/admin/admin-reviews");
                        break;
                    case 'admin':
                        history.push("/admin");
                        break;
                    default:
                        history.push("/car-warranty-reviews");
                        break;
                }
                window.location.reload(false);
            })
            .catch((err) => {
                notification.error({
                    message: 'Login Failed',
                    description: 'Please check your credentials and try again.',
                });
            });
    }

    const closeModal = () => {
        setLoginFormData({
            email: '',
            password: '',
        });
        showLogin();
    }

    const onFinish = (values) => {
        loginUser({ user: { email: values.email, password: values.password } });
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Update',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    return (
        <>
            {(textLink == true) && <Button className='loginText' type='link' onClick={() => showLogin()}>log in in here.</Button>}
            {(textLink == false) && <Button onClick={() => showLogin()} className="login px-3 py-2 flex items-center text-xs ml-3">Login</Button>}
            <Modal
                open={visible}
                centered
                className='flex jusitize-center items-center'
                closable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={() => showLogin()}>
                        Close
                    </Button>
                ]}
                onCancel={() => closeModal()}
                onFieldsChange={onFieldsChange}
            >
                <span id='login-form-wrapper'>
                    <Row id='login-form' className=''>
                        <Col span={24}>
                            <Form
                                {...layout}
                                form={form}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row className='flex'>
                                    <Col span={24}>
                                        <h4 className='text-2xl font-bold text-center mb-0'>Login to Your Account</h4>
                                    </Col>
                                </Row>
                                <div className='flex flex-col '>
                                    <Form.Item>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    required: true,
                                                    message: 'Please enter your email address.',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <label>Email Address</label>
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your password.',
                                                },
                                            ]}
                                        >
                                            <Input.Password visibilityToggle={true} />
                                        </Form.Item>
                                        <label>Password</label>
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row justify-content='center'>
                        {(sticky_user.user == null) &&
                            <Col span={14}><p className='text-lg mt-5 text-center'>Don't have an account? You can <Button type='link' onClick={() => navigateToCreateAccount()}><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500'>create a new account.</span></Button></p></Col>
                        }
                    </Row>
                </span>
            </Modal>
        </>
    )
}