import React from 'react';
import { useHistory } from "react-router-dom";
import optOutStyles from './OptOutRequest.module.css';
import NavBar from '../../Shared/NavBar';
import { Checkbox, Col, Layout, Row, Button, Form, Input, notification, Radio } from 'antd';
import { Link } from 'react-router-dom';
import Reaptcha from 'reaptcha';
const { Header, Footer, Sider, Content } = Layout;
const { useState, useEffect } = React;

export default function OptOutRequest() {
    let history = useHistory();

    const [verified, setVerified] = useState(false);

    const layout = {
        layout: 'horizontal',
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 10,
        },
    };

    const tailLayout = {
        wrapperCol: {
            span: 4,
        },
    };

    const submitOptOutRequest = async (optOutRequest) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/opt_out_requests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(optOutRequest),
        })
            .then((response) => {
                if (response.ok) {
                    notification.success({
                        message: 'Success',
                        description: 'Your request has been submitted.',
                    })

                    history.push("/help-support/thankyou");
                    return response.json();
                } else {
                    return response.text().then(text => { throw new Error(text); });
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Failed to Create Opt Out Request',
                    description: error,
                })
            })
    }

    const onFinish = (values) => {
        submitOptOutRequest({
            opt_out_request: {
                full_name: values.full_name,
                phone_number: values.phone_number,
                email: values.email,
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Update',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onVerify = (token) => {
        setVerified(true);
    }

    const validatePhoneNumber = (rule, value) => {
        if (value && !/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)) {
            return Promise.reject('Invalid phone number');
        }
        return Promise.resolve();
    };

    return (
        <Layout className={optOutStyles.layoutStyle}>
            <Header className={optOutStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <h1>Opt Out Request Form</h1>
                </Row>
                <Row justify="center" align="middle">
                    <p><strong>Request removal from our marketing lists.</strong></p>
                </Row>
            </Header>
            <Content id='page-body' className='container mx-auto'>
                <h2>Can I Opt out?</h2>
                <p>Yes. If you are getting unwanted advertising from WarrantyReviews.com, we may have inadvertently added your number or address. We apologize for this inconvenience and want to remedy the situation to prevent further phone calls or mailings to you. Please fill out the form on this page and click the submit button. We will remove you from our system so you are not contacted again.</p>

                <h2>Opt out Form</h2>
                <p>Please enter all of the fields using the details of where you are receiving correspondence. You will be removed from our mailing list immediately. There is no way for us to take you off the list if you are not solicited by WarrantyReviews.com or if we are unable to verify your identity due to inaccurate or incomplete details. Thank you. </p>

                <div id='opt-out-request-form' className='mt-5 md:mt-0 md:col-span-2'>

                    <Form
                        {...layout}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className='flex flex-col item-center justify-items-center mt-3'>
                            <Form.Item>
                                <Form.Item
                                    name="full_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your name.',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <label>Name</label>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item
                                    name="phone_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your phone number.',
                                        },
                                        { validator: validatePhoneNumber },
                                    ]}
                                >
                                    <Input placeholder='Phone Number' />
                                </Form.Item>
                                <label>Phone Number</label>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please enter your email address.',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Email Address' />
                                </Form.Item>
                                <label>Email Address</label>
                            </Form.Item>
                            <Form.Item>
                                <Reaptcha sitekey="6LeYI5cnAAAAANguadeF12t3zMRx5KnWKoUATWGM" onVerify={onVerify} />
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button
                                    disabled={!verified}
                                    className={optOutStyles.compareButton}
                                    type="danger"
                                    htmlType="submit"
                                >
                                    Opt Me Out
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Content>
        </Layout>
    )
}
