import React from 'react';
import { useHistory } from "react-router-dom";
import ccpaStyles from './CcpaRequest.module.css';
import NavBar from '../../Shared/NavBar';
import { Checkbox, Col, Layout, Row, Button, Form, Input, notification, Radio } from 'antd';
import { Link } from 'react-router-dom';
const { Header, Footer, Sider, Content } = Layout;
import Reaptcha from 'reaptcha';
const { useState, useEffect } = React;

export default function CcpaRequest() {
    let history = useHistory();
    const [verified, setVerified] = useState(false);

    const requestTypeOptions = [
        { label: 'Request to obtain any pieces of personal information WarrantyReviews.com collected about the consumer.', value: 'any_pieces' },
        { label: 'Request to obtain specific pieces of personal information WarrantyReviews.com collected about the consumer.', value: 'specific_pieces' },
        { label: 'Request to delete personal information WarrantyReviews.com has collected about the consumer.', value: 'delete' },
    ];

    const layout = {
        layout: 'horizontal',
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 10,
        },
    };

    const tailLayout = {
        wrapperCol: {
            span: 4,
        },
    };

    const submitCcpaRequest = async (ccpaRequest) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/ccpa_requests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(ccpaRequest),
        })
            .then((response) => {
                if (response.ok) {
                    notification.success({
                        message: 'Success',
                        description: 'Your request has been submitted.',
                    })

                    history.push("/help-support/thankyou");
                    return response.json();
                } else {
                    return response.text().then(text => { throw new Error(text); });
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Failed to Create CCPA Request',
                    description: error,
                })
            })
    }

    const onFinish = (values) => {
        submitCcpaRequest({
            ccpa_request: {
                full_name: values.full_name,
                phone_number: values.phone_number,
                email: values.email,
                california_resident: values.california_resident,
                consumer_request: values.consumer_request,
                type_of_ccpa_requests: values.type_of_ccpa_requests,
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Update',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onVerify = (token) => {
        setVerified(true);
    }

    const validatePhoneNumber = (rule, value) => {
        if (value && !/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)) {
            return Promise.reject('Invalid phone number');
        }
        return Promise.resolve();
    };

    return (
        <Layout className={ccpaStyles.layoutStyle}>
            <Header className={ccpaStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <h1>CCPA Request Form</h1>
                </Row>
            </Header>
            <Content id='page-body' className='container mx-auto'>
                <p><strong>For Utah, Connecticut, Colorado, Virginia, and California residents - CCPA: Consumer Personal Information Request Form</strong></p>
                <p style={{ marginTop: '20px' }}>Instructions: California residents can use this form to submit requests under the California Consumer Privacy Act (CCPA) regarding personal information collected by WarrantyReviews.com. WarrantyReviews.com reserves the right to refuse requests, in part or in whole, to the extent permitted by law, if we are unable to verify your identity, or if we cannot verify your authority to act on behalf of another person. For certain requests, we may ask for additional information or documents to verify the identity of the consumer who is the subject of the request. The information provided through this form will be used to respond to your request, including verifying identity, identifying personal information responsive to your request, and keeping records of your request.</p>

                <div id='ccpa-form' className='mt-5 md:mt-0 md:col-span-2'>

                    <Form
                        {...layout}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className='flex flex-col item-center justify-items-center mt-3'>
                            <Form.Item>
                                <Form.Item
                                    name="full_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your name.',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <label>Name</label>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item
                                    name="phone_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your phone number.',
                                        },
                                        { validator: validatePhoneNumber },
                                    ]}
                                >
                                    <Input placeholder='Phone Number' />
                                </Form.Item>
                                <label>Phone Number</label>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please enter your email address.',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Email Address' />
                                </Form.Item>
                                <label>Email Address</label>
                            </Form.Item>
                            <Form.Item>
                                <label>Are you a California resident?</label>
                                <Form.Item
                                    name="california_resident"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select if you are a California resident.',
                                        }
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>

                            </Form.Item>
                            <Form.Item>
                                <label>Are you the consumer?</label>
                                <Form.Item
                                    name="consumer_request"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select if you are a consumer.',
                                        }
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>

                            </Form.Item>
                            <Form.Item>
                                <Reaptcha sitekey="6LeYI5cnAAAAANguadeF12t3zMRx5KnWKoUATWGM" onVerify={onVerify} />
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <p>
                                        <strong>Request for online activity information</strong><br />
                                        If your request applies to online activity information that WarrantyReviews.com
                                        may have collected through cookies or similar technologies, you must make your
                                        request from the browser or device that you have previously used to access
                                        WarrantyReview.com's websites or apps. This allows us to read any identifier
                                        that we have assigned to your browser or device.
                                    </p>
                                </Col>
                            </Row>
                            <p><strong>Specify the request(s) - check all that apply</strong></p>
                            <Form.Item>
                                <Form.Item
                                    name="type_of_ccpa_requests"
                                    rule={[
                                        {
                                            required: true,
                                            message: 'Please select the type of request you are making.',
                                        }
                                    ]}
                                >
                                    <Checkbox.Group options={requestTypeOptions} defaultValue={['Apple']} />
                                </Form.Item>
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <p>
                                        By submitting this form, I hereby certify that the information entered into
                                        this form is complete, accurate, and up-to-date, and that I am the consumer
                                        who is the subject of the request or have been authorized by that consumer
                                        to act on his/her behalf, as indicated above. I understand that it may be
                                        necessary for WarrantyReviews.com to verify the identity of the consumer
                                        and/or authorized agent for this request, and additional information may
                                        be requested for this purpose. For more information about how we handle
                                        your Personal Data, please review our <Link to='/privacy_policy'>Privacy Policy</Link>.
                                    </p>
                                </Col>
                            </Row>
                            <Form.Item {...tailLayout} style={{ marginTop: '20px' }}>
                                <Button
                                    disabled={!verified}
                                    className={ccpaStyles.compareButton}
                                    type="danger"
                                    htmlType="submit"
                                >
                                    Submit Request
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Content>
        </Layout>
    )
}
