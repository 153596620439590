import * as React from 'react'
import MetaTags from 'react-meta-tags';
import { Grid, List, Space, Rate, Row, Col, Layout, Image } from 'antd';
import { Link, LinkProps } from 'react-router-dom';
import CompanyCard from './../Home/children/CompanyCard';
import reqwest from "reqwest";
import { useStore } from '../Providers/CompanyStore';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner'
import companyIndexStyles from './CompanyIndex.module.css';

const { Header, Content } = Layout;

const { useState, useEffect } = React;
const { useBreakpoint } = Grid;

export default function CompaniesIndex() {

    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [componantList, setComponantList] = useState([]);
    const [, dispatch] = useStore();

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/companies`,
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            type: "json"
        });

        setCompanyList(response.companies.sort((a, b) => a.aggregate_rating < b.aggregate_rating ? 1 : -1));

        let list = response.companies.sort((a, b) => (a.aggregate_rating < b.aggregate_rating) ? 1 : -1);

        setComponantList(list.map((company, index) => {
            return (
                <CompanyCard key={company.id.toString()} index={index} company={company} isLoading={isLoading} />
            )
        }));

        setIsLoading(false);
    };

    useEffect(() => {
        customFetch({});
    }, []);

    const IconText = ({ icon, text }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    return (
        <Layout className={companyIndexStyles.layoutStyle}>
            <MetaTags>
                <title>Extended Car Warranty Companies List & Reviews | Warranty Reviews</title>
                <meta property="og:title" content="Extended Car Warranty Companies List & Reviews | Warranty Reviews" />
                <meta name="description" content="Browse our list of extended car warranty companies online. Compare coverage options and costs. Read customer reviews. Get an online quote." />
            </MetaTags>
            <Header id="page-header" className={companyIndexStyles.header}>
                <h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Extended Auto Warranty Companies</h1>
            </Header>
            <Content id="page-body" className="container mx-auto">
                <Row>
                    {(isLoading ? <LoadingSpinner /> :
                        (componantList.length > 0 ? componantList : <p>No companies found</p>)
                    )}
                </Row>
            </Content>
        </Layout>
    )
}
