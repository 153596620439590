import React from 'react';
import privacyPolicyStyles from './PrivacyPolicy.module.css';
import { Layout, Row } from 'antd';
import { Link } from 'react-router-dom';
const { Header, Content } = Layout;

export default function PrivacyPolicy() {
    return (
        <Layout className={privacyPolicyStyles.layoutStyle}>
            <Header className={privacyPolicyStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <h1>Privacy Policy</h1>
                </Row>
            </Header>
            <Content id='page-body' className='container mx-auto'>
                <div id='privacy-policy'>

                    <p>This is the Privacy Policy for WarrantyReviews.com
                        (collectively, the &quot;Site&quot;), which is assembled by WarrantyReviews, LLC (the
                        &quot;Company&quot; or &quot;we&quot; or &quot;us&quot;). The materials and
                        information on this Site are intended for informational purposes only. The
                        reviews are not the thoughts or opinions of WarrantyReviews, LLC. This Site may contain
                        links to third party websites as well as third party advertisements.
                        WarrantyReviews.com is not responsible for and has no liability for the privacy
                        or other practices of any such third party. We recommend that you review the
                        privacy policies of each website you visit. With regard specifically to this
                        WarrantyReviews.com site, please see our full Privacy Policy immediately below.</p>



                    <h2>Your Acceptance of this Privacy Policy</h2>

                    <p>By using the Site, including, without limitation,
                        subscribing to services and/or continuing to receive information from the
                        Company, you signify your acceptance of this Privacy Policy, and you expressly
                        consent to our use and disclosure of your personal information in accordance
                        with this Privacy Policy. This Privacy Policy is subject to the Terms of Use
                        posted on the Site.</p>



                    <h2>California Consumer Privacy Act - CCPA</h2>

                    <p>California residents, &quot;consumers&quot;, have certain
                        rights under the California Consumer Protection Act (&quot;CCPA&quot;) with
                        respect to their personal information. The CCPA provides:</p>

                    <ul className={privacyPolicyStyles.listStyle}>
                        <li>The right to know about the personal information a business
                            collects about them and how it is used and shared;</li>
                        <li>The right to request a copy of the specific personal information
                            collected about them during the 12 months before their request;</li>
                        <li>The right to delete personal information collected from them
                            (with some exceptions);</li>
                        <li>The right to opt-out of the sale of their personal information
                            (or, for consumers who are under 16 years old, the right not to have their
                            personal information sold absent their, or their parent's, opt-in); and</li>
                        <li>The right to non-discrimination for exercising their CCPA rights.</li>
                    </ul>

                    <p>We will not discriminate against you for exercising any of
                        your CCPA rights. You will not be denied goods or services, charged different
                        prices for goods or services, or provided a different level or quality of goods
                        or services.</p>



                    <h2>Information We Collect</h2>

                    <p>We collect information that may personally identify you
                        (only if you choose to share such information with us). Personal Information is
                        defined as &quot;information that identifies, relates to, describes, is capable
                        of being associated with, or could reasonably be linked, directly or
                        indirectly, with a particular consumer or household.&quot; Examples of
                        categories of personal information we may collect are: identifiers such as, a
                        real name, address, telephone number, email address, account name, billing
                        information, or other similar identifiers; Internet or other electronic network
                        activity information, including, but not limited to: browsing history and
                        information regarding a consumer's interaction with a website, application, or
                        advertisement; Commercial information, including products or services
                        purchased; Biometric information; Characteristics of protected classifications
                        under California or federal law; Geolocation data; Audio, electronic, visual,
                        thermal, olfactory, or similar information; Professional or employment-related
                        information; and Education information. This list is non-exhaustive and does
                        not mean that all examples of personal information were collected. For example,
                        we may collect personal information from you when you register on the Site,
                        subscribe to one of our services, and at other times. We also collect credit
                        card and debit card information in connection with any purchase you make on or
                        through the Site. The decision to provide this information is optional;
                        however, if you decide not to register or provide such information, you may not
                        be able to use certain features of the Site or other services.</p>
                    <br />
                    <p>We may automatically collect non-personal information that
                        does not identify an individual user, such as IP (internet protocol) address,
                        ISP (internet service provider), the Web browser used to visit the Site, the
                        time the Site is visited, which Web pages were visited on the Site, a unique
                        mobile device identifier (in connection with the use of the Sites or related
                        services from mobile devices) and other anonymous Site usage data.</p>
                    <br />
                    <p>CCPA excludes consumer information that is de-identified or
                        in the aggregate consumer information and information that is publicly
                        available from federal, state, or local government records.</p>



                    <h2>Cookies</h2>

                    <p>We may collect personal information and non-personal
                        information from users via &quot;cookies&quot; (small text files placed by us
                        on user computers), single-pixel GIF image files (also called &quot;Web
                        beacons&quot;), Web server log analysis and other similar technologies. Such
                        information may be used to track usage trends and enhance the user experience,
                        and may be shared with third parties. Please <Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500  " to='/help-support/opt-out-request'>click here</Link> for more information and to
                        opt out.</p>
                    <br />
                    <p>To the extent third parties may place advertising on the
                        Site, such third parties may utilize cookies or other tracking technologies. We
                        are not responsible for information collected by third parties in this manner,
                        nor for the collection or use of information by other sites to which the Site
                        is linked.</p>


                    <p><strong>We do not currently respond to Do Not Track signals or other
                        similar signals.</strong></p>


                    <h2>Promotional Communications by Email or Text Message</h2>

                    <p>You may opt out of receiving promotional emails from Company
                        by following the instructions in those emails or by contacting us as noted
                        below. To opt out of receiving text messages from Company at any
                        time, reply to a text from us with the word “STOP.” A confirmation message of
                        your opt out will be sent to your mobile number but no additional messages will
                        be sent to you unless you opt in again.</p>



                    <h2>User Information</h2>

                    <p>We use your personal information and non-personal information for internal purposes, such as to provide you with the Site, make improvements to the Site, provide advertisements, content and information that we think will be of interest to you, respond to your requests and to otherwise communicate with you, and to comply with the laws and regulations and our legal obligations.</p>



                    <h2>Sharing Information; Your Consent to Sharing</h2>

                    <p>We may share your personal information and non-personal
                        information with third parties for the purpose of allowing them to provide
                        information about products and services that we think will be of interest to
                        you.</p>

                    <p>By providing personal information on or through the Site, or
                        in connection with services provided by the Company thereon or in connection
                        therewith, you agree:</p>
                    <ul className={privacyPolicyStyles.listStyle}>
                        <li>all information you have provided is true and correct to the best
                            of your knowledge;</li>
                        <li>you are establishing a business relationship with Company; and</li>
                        <li>you are requesting and expressly consenting to be contacted via
                            telephone, email, sms / txt message, and/or mail by us and our partners and
                            affiliates, regardless of whether your telephone number is on any Do Not Call
                            list(s)</li>
                    </ul>

                    <p>From time to time, we may establish a business relationship
                        with other businesses whom we believe trustworthy and who have confirmed that
                        their privacy practices are consistent with ours (&quot;Service
                        Providers&quot;). For example, we may contract with Service Providers to
                        provide certain services, such as hosting and maintenance, customer
                        relationship management, data storage and management, and direct marketing
                        campaigns. We provide our Service Providers with only the information necessary
                        for them to perform these services on our behalf. Each Service Provider must
                        agree to use reasonable security procedures and practices, appropriate to the
                        nature of the information involved, in order to protect your Personal
                        Information from unauthorized access, use or disclosure.</p>

                    <br />

                    <p>We may work with third-party marketing and advertising
                        companies (&quot;Ad Networks&quot;). These companies may collect and use
                        information about your use of the Site or services in order to provide
                        advertisements about goods and services that may be of interest to you.
                        Advertisements may be shown via the Site, the services, or third-party sites or
                        sent via direct mail. These companies may place or recognize a unique cookie on
                        your computer or use other technologies such as Web beacons. Our Privacy Policy
                        does not cover any use of information that an Ad Network may collect from you.
                        It also does not cover any information that you may choose to provide to an Ad
                        Network or to an advertiser whose goods or services are advertised through the
                        Site. If you disclose information through the Services such as through a
                        customer review, that information can be viewed publicly and may be collected
                        and used by third parties without our knowledge or permission. For more
                        information about ad cookies, and to &quot;opt-out&quot; of the collection of
                        information by some companies that allow you to do so,
                        please <Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500  " to='/help-support/opt-out-request'>click here</Link>.
                    </p>

                    <br />

                    <p>We may share personal information and non-personal
                        information with businesses controlling, controlled by, or under common control
                        with, Company. If Company is merged, acquired, or sold, or in the event of a
                        transfer of some or all of our assets, we may disclose or transfer personal
                        information and non-personal information in connection with such transaction.
                        Or we may share personal information for a business purpose (as defined by the
                        CCPA) which includes: Auditing interactions with consumers and concurrent
                        transactions; detecting security incidents or illegal activity; performing
                        services on behalf of the business (including maintenance or servicing accounts
                        and customer service, etc.); debugging to identify and repair errors;
                        short-term, transient use; internal research for technological development; and
                        maintaining the quality or safety of a service or device that is owned,
                        manufactured, or controlled by the company.</p>

                    <br />

                    <p>We cooperate with government and law enforcement officials
                        and private parties to enforce and comply with the law. We may disclose
                        personal information and non-personal information about you to government or
                        law enforcement officials or private parties when compelled by law or court
                        order to do so or if, in our discretion, we believe it is necessary or
                        appropriate in order to respond to legal requests (including court orders and
                        subpoenas), to protect the safety, property or rights of Company or of any
                        third party, to prevent or stop any illegal, unethical, or legally actionable
                        activity, or to comply with the law.</p>



                    <h2>No Collection of Information from Children</h2>

                    <p>The Site is not intended for users under the age of 18, nor
                        do we knowingly collect, retain or share information in connection with the
                        Site from children under the age of 18.</p>



                    <h2>Safeguarding Your Information</h2>

                    <p>We endeavor to take reasonable steps to safeguard and
                        protect our users' information. We utilize electronic and physical security to
                        reduce the risk of improper access to or manipulation of the information you
                        submit during transmission and storage, but cannot and do not guarantee the
                        security or integrity of the information. We shall have no liability for
                        breaches of security or integrity or third-party interception in transit.
                        Please be advised that any transmission of data at or through the Site is at
                        your own risk.</p>



                    <h2>External Links</h2>

                    <p>The Site may contain links to other sites, or allow others
                        to send you such links. A link to a third party's site does not mean that we
                        endorse it or that we are affiliated with it. We do not exercise control over
                        third-party sites. You access such third-party sites or content at your own
                        risk. You should always read the privacy policy of a third-party site before
                        providing any information to the site.</p>



                    <h2>Right to Request Disclosure of Information We Collect andShare</h2>

                    <p>You have the right to request that we disclose certain
                        information pertaining to their personal information that we have collected and
                        shared over the past 12 months. Once we receive and verify your request, we
                        will respond in a format that is portable, free of charge for the consumer, and
                        within the required 45 days as outlined by the CCPA. If more time is required
                        to fulfill the request, we will inform you of the reason and the needed
                        extension period, of up to 90 days, in writing. A consumer can ask for the
                        following information:</p>

                    <ul className={privacyPolicyStyles.listStyle}>
                        <li>The categories and specific pieces of personal information that
                            we collected about you;</li>
                        <li>The categories of sources from which your personal information
                            was collected;</li>
                        <li>The business or commercial purpose for collecting or sharing your
                            personal information; and</li>
                        <li>The categories of third parties with whom we shared your personal
                            information.</li>
                    </ul>

                    <p>To exercise your right to request disclosure of your
                        personal information that we collected and shared,
                        either <Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500  " to='/help-support/ccpa-request'>click here</Link> or
                        contact us at 1-(800) 645-8780. Consumers may make a verifiable request twice
                        within in a 12-month period.</p>



                    <h2>Right to Request Deletion of Personal Information</h2>

                    <p>You have the right to request that we delete any of your
                        personal information that we collected from you, except for certain situations
                        when your personal information is necessary for a reason related to our business,
                        such as: providing goods or services that you requested; performing a contract
                        we entered into with you; complying with legal obligations; detecting security
                        incidents; or using the information for internal purposes that a consumer might
                        reasonably expect. And from receiving future offers in connection with the Site
                        To exercise your right to request to delete your personal information that we
                        collected, either <Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500 " to='/help-support/ccpa-request'>click here</Link> or
                        contact us at 1-(800) 645-8780. Unless an exception applies,
                        once we receive and verify your request, we will delete your personal
                        information from our records as required by the CCPA. Consumers may make a
                        verifiable request twice within in a 12-month period.</p>



                    <h2>DO NOT SELL MY PERSONAL INFORMATION</h2>

                    <p>You may to opt out from having your personal information
                        &quot;sold&quot; (as defined by the CCPA),
                        either <Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500  " to='/help-support/opt-out-request'>click here</Link> or contact us at 1-(800) 645-8780.
                        Once we receive and verify your request,
                        we will not &quot;sell&quot; your personal information.</p>

                    <h2>Contacting Us</h2>

                    <p>If you have any questions or concerns about this Privacy
                        Policy or our privacy practices, you may write to us at:</p>
                    <br />
                    <p>WarrantyReviews, LLC<br />
                        Attn: Customer Service<br />
                        30 N Gould<br />
                        Sheridan, WY 82801</p>
                    <p>You can also email us at customerservice@warrantyreviews.com. Please provide complete information to facilitate your
                        request, as well as your complete contact information.</p>
                    <br />




                    <h2>Privacy Policy Changes</h2>

                    <p>From time to time, we may change this Privacy Policy. If we
                        decide to change this Privacy Policy, we will inform you by posting the revised
                        Privacy Policy on the Site. Those changes will go into effect on the revision
                        date shown in the revised Privacy Policy. Your continued use of our Site or
                        services constitutes your consent to be bound by the revised Privacy Policy.</p>

                    <p><small>Effective Date: 07/28/2022</small></p>

                </div>
            </Content>
        </Layout>
    )
}
