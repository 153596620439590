import React from 'react';
import { Grid, Col, Layout, Row, Button, Image } from 'antd';
import reviewThankYouStyles from './ReviewThankYou.module.css';

const { Header } = Layout;
const { useBreakpoint } = Grid;

export default function ReviewThankYou(props) {
    const { xs, sm, md, lg } = useBreakpoint();
    let pFontSize = '';
    let pLineHeight = '';
    let h1FontSize = '';
    let h1LineHeight = '';

    if (xs) {
        pFontSize = '1.375rem';
        pLineHeight = '1.65rem';
        h1FontSize = '3.516rem';
        h1LineHeight = '3.625rem';
    }

    if (sm) {
        pFontSize = '1.375rem';
        pLineHeight = '1.65rem';
        h1FontSize = '3.516rem';
        h1LineHeight = '3.625rem';
    }

    if (md) {
        pFontSize = '1.375rem';
        pLineHeight = '1.65rem';
        h1FontSize = '3.516rem';
        h1LineHeight = '3.625rem';
    }

    if (lg) {
        pFontSize = '1.375rem';
        pLineHeight = '1.65rem';
        h1FontSize = '4.688rem';
        h1LineHeight = '5.625rem';
    }

    return (
        <Layout className={reviewThankYouStyles.layoutStyle}>
            <Row justify='center' align='middle'>
                <Col><h1 style={{ fontSize: h1FontSize, lineHeight: h1LineHeight, textAlign: 'center', marginBottom: '30px' }}>Thank You For Submitting a Review</h1></Col>
            </Row>
            <Row justify="center" align="middle">
                <Col>

                </Col>
            </Row>
        </Layout >
    )
}
