import React from 'react';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;

const { useBreakpoint } = Grid;

export default function ChoosingACarWarrantyCompany() {
    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';
    let pMarginBottom = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
        pMarginBottom = '10px';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
        pMarginBottom = '10px';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
        pMarginBottom = '10px';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
        pMarginBottom = '10px';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Choosing A Car Warranty Company | Warranty Reviews</title>
                <meta property="og:title" content="Choosing A Car Warranty Company | Warranty Reviews" />
                <meta name="description" content="The car warranty company you select is just as important as the policy you choose. It's best to pick a car warranty company with a reputation for fairness and honesty." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Choosing A Car <br />Warranty Company</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>Choosing a Car Warranty Company</span></p></Col>
                </Row>
            </section>
            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>Be an informed consumer. What looks great at first glance might not always be the case. Researching policies, reading reviews and
                            asking the right questions will make the decision-making process smoother and instill confidence in your decision.
                        </p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/man-in-car.jpg'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <p style={{ lineHeight: pLineHeight }}>The car warranty company you select is just as important as the policy you choose. That’s because when you purchase a new or used car, you are also buying the maintenance and repairs that come with it. The best way to protect the future of your automobile is to pick a car warranty company with a reputation for fairness and honesty.</p>
                        <p style={{ lineHeight: pLineHeight }}>Used to help save money on repairs and damaged parts, car warranties are a great way to prepare for the unexpected. However, with so many companies contending to consider, matching up with the right car warranty company can feel like finding a needle in a haystack.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Options to Consider When Choosing a Car Warranty Company</h2>
                        <p style={{ lineHeight: pLineHeight }}>As the car warranty market continues to grow, it is more important than ever find a company that provides you with the ins and outs of your coverage. Remember, a good company will take the time to explain their plans and work to keep their customers satisfied.</p>
                        <p style={{ lineHeight: pLineHeight }}>Here is a list of plan options and service benefits to consider when choosing a car warranty company.</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Compare the waiting periods for warranty coverage (the number of days and miles). This will help you score a flexible policy that meets your needs.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Partner with a provider who covers both repairs and labor charges.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Select a car warranty company that provides 24/7 roadside assistance and towing — ask about other emergency benefits.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Examine financial factors outside of the rate, including deductibles and liability limits. </p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Review contract terms to determine if the plan is a month-to-month subscription or a long-term contract. If it is a contract, ask about cancellation and refund policies.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Understand the claims process and the accessibility of approved repair shops.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Protect Yourself Against Untrustworthy Service Providers</h2>
                        <p style={{ lineHeight: pLineHeight }}>To protect yourself and your money, be a savvy shopper. Learn as much as you can about the company, their history, and the services they provide. Research by visiting their websites, reading online testimonials, and studying reviews from third parties like WarrantyReviews.com.</p>
                        <p style={{ lineHeight: pLineHeight }}>The following suggestions may help you avoid untrustworthy warranty companies.</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Before signing on with a warranty provider, verify that the company is based in the United States.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Learn about the channels of communication the warranty company uses. Any good company will have an email address, phone number, app, or website that is readily accessible for their clients. Many even have customer portals.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Find a warranty company that has been operating for a long time. They will be better suited to answer any questions based on experience and guide you towards a solid warranty.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>The Difference Between Car Warranties and Car Insurance</h2>
                        <p style={{ lineHeight: pLineHeight }}>Finding coverage can be confusing if you don't know the difference between warranties and car insurance. Here is some clarification:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Both car warranties and car insurance give owners protection from unexpected issues.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>A major difference between the two is that car insurance is required by law, while car warranties are not.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Another big distinction is that car warranties do not cover damages from accidents where the owner is responsible. Auto insurance policies, however, will reimburse the owner for injuries to people or damage to their vehicle. This can make car insurance more expensive.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Avoid purchasing a warranty from a lead provider. Lead providers seek out potential customers and often deliver misleading information. Car warranty companies are more reputable and experienced in addressing any coverage concerns you may have.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Make sure the warranty company records their sales and claims calls. Not only is it required by law, it helps maintain quality assurance.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Warranty companies are not allowed to use robocalls or engage in unfair solicitation.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Go with your gut! If you see any potential warning signs from your car warranty company, explore other options, particularly if the policy appears to be a sweet deal that cannot be passed up.</p></li>
                        </ul>
                        <p style={{ lineHeight: pLineHeight }}>As with a lease, mortgage or other legal documents, don’t forget to read the fine print! Know what you are paying for and ask questions about anything you do not understand. Some car warranty companies have contracts loaded with hidden fees and terms designed to protect them from paying your claim.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Know the Right Questions to Ask</h2>
                        <p style={{ lineHeight: pLineHeight }}>Be an informed consumer. Research the policies, practices, and procedures of the warranty company. Learn how these protocols compare with their competition. Doing research and asking questions will help you get an overall picture of your coverage options and make the decision-making process smoother.</p>
                        <p style={{ lineHeight: pLineHeight }}>Here are some sample questions you should ask the car warranty representative you speak with:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Is there a penalty for canceling my car warranty?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>What happens when my car warranty expires?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Are there restrictions on where I can take their vehicle for repairs?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>What repair charges are covered by this warranty?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Can I transfer my warranty to another car?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Is there a rental car allowance if my car is left in the shop overnight?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Do you charge collection fees if I decide to end my policy?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Are there any additional charges not listed in this agreement?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>How long does it take a claim to be processed?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>How will claims be paid out?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Do you charge cancellation fees?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>What are the expiration details of my policy?</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>How does your company handle your deductibles?</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>The Final Steps for Selecting the Right Car Warranty Company</h2>
                        <p style={{ lineHeight: pLineHeight }}>The final step in choosing a car warranty is signing on with a provider.</p>
                        <p style={{ lineHeight: pLineHeight }}>Explore the warranty company’s reputation online and see if there are any articles about their business and the customer service they provide.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
