import * as React from "react";
import reqwest from "reqwest";
import { Divider } from 'antd';
import useToken from '../useToken';
import { useParams } from 'react-router-dom';
const { useState, useEffect } = React;

export default function User(props) {
    let { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({});
    const { token, setToken } = useToken();


    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/users/` + id,
            method: "get",
            headers: {
                "Accept": "application/json",
                "access-token": token.access_token,
                "client": token.client,
                "uid": token.uid
            },
            type: "json"
        });
        console.log(response.user);
        setUser(response.user);

        setIsLoading(false);
    };

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        (isLoading ? <div>Loading...</div> :
            <div className="container mx-auto">
                <h1 className="text-lg">User: {user.first_name} {user.last_name}</h1>
                <Divider />
                <div className="flex flex-wrap">

                </div>


            </div>
        )
    )
}