import React from 'react'
import { Card, Grid, Layout, List, Col, Rate, Row } from 'antd';
import companyShowStyles from '../CompanyShow.module.css';
import reqwest from "reqwest";
import dayjs from 'dayjs';
import LoadingSpinner from '../../Shared/LoadingSpinner/LoadingSpinner';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

const { Content } = Layout;

const { useState, useEffect } = React;
const { useBreakpoint } = Grid;


export default function CompanyReviews({ company, seoName }) {
    dayjs.extend(utc);
    dayjs.extend(tz);

    const [isLoading, setIsLoading] = useState(false);
    const [approvedReviews, setApprovedReviews] = useState([]);
    const [featuredReviews, setFeaturedReviews] = useState([]);

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/companies/` + seoName + '/company_reviews',
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            type: "json"
        });

        const reviews = response.reviews.sort((a, b) => a.datetime < b.datetime ? 1 : -1).filter(obj => {
            return obj.status === 'approved';
        })
        setApprovedReviews(reviews.slice(2))
        setFeaturedReviews(reviews.slice(0, 2))
        setIsLoading(false);
    };

    useEffect(() => {
        if (company && seoName) {
            customFetch({});
        }
    }, []);

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
    }

    return (
        <>
            <section className={companyShowStyles.featuredReviews}>
                <Row justify="center" align="middle">
                    <Col align="center" lg={{ span: 8 }}>
                        <h3>Featured Reviews</h3>
                    </Col>
                </Row>
                <Row justify="center" align="middle">
                    <section type="flex" justify="center" className="container mx-auto">
                        <List
                            size={'small'}
                            loading={isLoading}
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 2,
                                xl: 2,
                                xxl: 2
                            }}
                            dataSource={featuredReviews}
                            renderItem={(item) => (
                                <List.Item >
                                    <Card loading={isLoading} size={'small'} bordered={false}>
                                        <Row justify='space-between'>
                                            <Col xs={{ span: 14 }} lg={{ span: 11 }}><p><strong><small>{item.full_name}</small></strong></p></Col>
                                            <Col lg={{ span: 9 }}><Rate allowHalf disabled defaultValue={item.rating} style={{ color: '#EE9924', fontSize: 25 }} /></Col>
                                        </Row>
                                        <Row>
                                            <Col lg={{ span: 5 }}><p><small>{dayjs(item.datetime).tz('America/Chicago').format('MM/DD/YYYY')}</small></p></Col>
                                        </Row>
                                        <Row><Col><p>{item.content}</p></Col></Row>
                                        <Row align="center"><Col><p className={companyShowStyles.source}><strong><small>Source: {item.source}</small></strong></p></Col></Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </section>
                </Row>
            </section>
            <Content className="container mx-auto">
                <section id="reviewList" className={companyShowStyles.reviewList}>
                    <List
                        itemLayout="vertical"
                        size="large"
                        loading={isLoading}
                        pagination={{
                            onChange: page => {
                                console.log(page);
                            },
                            pageSize: 10,
                        }}
                        dataSource={approvedReviews}
                        renderItem={item => (
                            <List.Item
                                className={companyShowStyles.listItem}
                                key={item.id}
                                actions={[
                                ]}
                            >
                                <Row gutter={10} justify='space-between'>
                                    <Col xs={{ span: 20 }} md={{ span: 6 }} lg={{ span: 4 }}>
                                        <p><strong>{item.full_name}</strong></p>
                                    </Col>
                                    <Col xs={{ span: 20 }} md={{ span: 6 }} lg={{ span: 3, offset: 8 }}>
                                        <p><strong>{dayjs(item.datetime, 'YYYY-MM-DD').format('MM/DD/YYYY')}</strong></p>
                                    </Col>
                                    <Col xs={{ span: 20 }} md={{ span: 6 }} lg={{ span: 5 }}>
                                        <Rate allowHalf disabled defaultValue={item.rating} style={{ color: '#EE9924', fontSize: 25 }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>{item.content}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={{ span: 5 }}>
                                        <p><small>Source: {item.source}</small></p>
                                    </Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                </section>
            </Content>
        </>
    )
}
