import React from 'react';
import quoteThankYouPageStyles from './QuoteThankYouPage.module.css';
import MetaTags from 'react-meta-tags';
import NavBar from '../Shared/NavBar';
import { Col, Divider, Grid, Layout, Row } from 'antd';
const { useBreakpoint } = Grid;


export default function CcpaThankYou() {

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    return (
        <Layout className={quoteThankYouPageStyles.layoutStyle}>
            <MetaTags>
                <title>Extended Car Warranty Companies Request A Quote| Warranty Reviews</title>
                <meta property="og:title" content="Extended Car Warranty Companies List & Reviews | Warranty Reviews" />
                <meta name="description" content="Browse our list of extended car warranty companies online. Compare coverage options and costs. Read customer reviews. Get an online quote." />
            </MetaTags>
            <section className={quoteThankYouPageStyles.headerStyle}>
                <Row className='mb-3'>
                    <Col span={24}><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Thank You For Your Request</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={quoteThankYouPageStyles.dividerStyle} /></Col>
                </Row>
            </section>
            <Row className='flex item-center justify-center mt-5' style={{ textAlign: 'center', justifyContent: 'center', marginTop: '50px' }}>
                <Col span={14}>
                    <span className='text-center text-lg font-light'>
                        Your request has been successfully submitted.
                    </span>
                </Col>
            </Row>
        </Layout>
    )
}
