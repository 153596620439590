import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Col, Form, Input, Layout, notification, Row, Select, Space } from 'antd';
import { userStore } from '../Providers/UserStore';
import { addUser } from '../Providers/UserReducer';
import createAccountStyles from './CreateAccount.module.css';
import Login from '../Login/Login';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default function CreateAccount() {
    const [companies, setCompanies] = useState([]);
    const [isCustomer, setIsCustomer] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordVisible, setPasswordVisible] = useState(false);

    let history = useHistory();
    const [, dispatch] = userStore();

    const customerOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ]

    const layout = {
        layout: 'horizontal',
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 10,
        },
    };

    const tailLayout = {
        wrapperCol: {
            span: 12,
        },
    };

    const getCompanies = async () => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.text().then(text => { throw new Error(text) })
                }
            })
            .then((json) => {
                setCompanies(json.companies.map(company => {
                    return { label: company.company_name, value: company.id }
                }))
                // Add Other option to companies
                setCompanies((prevState) => [...prevState, { label: 'Other', value: 0 }])
                return json
            })
            .catch((error) => {
                notification.error({
                    message: 'Error: Please try again.',
                    description: 'Registration failed. Please confirm your passwords match and are at least 8 characters.',
                });
            });
    };

    const loginUser = async (credentials) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        })
            .then((res) => {
                if (res.ok) {
                    localStorage.setItem("access-token", res.headers.get("access-token"));
                    localStorage.setItem("uid", res.headers.get("uid"));
                    localStorage.setItem("client", res.headers.get("client"));
                    return res.json();
                } else {
                    return res.text().then(text => { throw new Error(text) })
                }
            })
            .then((json) => {
                dispatch(addUser(json));
                switch (json['user']['user_role']) {
                    case 'superadmin':
                        history.push("/admin/admin-reviews");
                        break;
                    case 'admin':
                        history.push("/admin");
                        break;
                    default:
                        history.push("/car-warranty-reviews");
                        break;
                }
                window.location.reload(false);
            })
            .catch((error) => {
                notification.error({
                    message: 'Error: Please try again.',
                    description: 'Registration failed. Please confirm your passwords match and are at least 8 characters.',
                });
            });
    }

    const onFinish = (values) => {
        loginUser({
            user: {
                company_id: values.company_id,
                customer: values.customer,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                password_confirmation: values.password_confirmation,
                password: values.password,
                username: values.first_name + "_" + values.last_name,
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Update',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <div className="container mx-auto mb-4">
                <section className="login-container">
                    <div id='create-account' className="mt-5 md:mt-0 md:col-span-2">
                        <Form
                            {...layout}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Row className='flex' justify="center" >
                                <Col span={10}>
                                    <h1 className="text-2xl font-bold text-center mb-0 mt-3">Create a WarrantyReviews.com Account</h1>
                                </Col>
                            </Row>
                            <div className='flex flex-col item-center justify-items-center mt-3'>
                                <Form.Item>
                                    <Form.Item
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your first name.',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <label>First Name</label>
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your last name.',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <label>Last Name</label>
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your email address.',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <label>Email Address</label>
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="customer"
                                        style={{ marginBottom: '0px', marginRight: '0px' }}
                                        className='flex-1'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Are you a past or current customer of a car warranty company?',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={customerOptions}
                                            placeholder="Current or previous customer?"
                                            className='flex-1'
                                            onChange={(value) => setIsCustomer(value === true ? true : false)}
                                            style={{ marginBottom: '0px', marginRight: '0px', border: '1px solid #9D9E9E', borderRadius: '5px' }}
                                        />
                                    </Form.Item>
                                    <label>Are you a past or current customer of a car warranty company?</label>
                                </Form.Item>
                                <Form.Item style={{ display: isCustomer ? 'block' : 'none' }}>
                                    <Form.Item
                                        name="company_id"
                                        style={{ marginBottom: '0px', marginRight: '0px', display: isCustomer ? 'block' : 'none' }}
                                        className='flex-1'
                                        rules={[
                                            {
                                                required: isCustomer,
                                                message: 'Which car warranty company?',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={companies}
                                            placeholder="Company"
                                            className='flex-1'
                                            style={{ marginBottom: '0px', marginRight: '0px', border: '1px solid #9D9E9E', borderRadius: '5px' }}
                                        />
                                    </Form.Item>
                                    <label>Which car warranty company?</label>
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your password.',
                                            },
                                        ]}
                                    >
                                        <Space direction="horizontal">
                                            <Input.Password
                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                placeholder="input password"
                                                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                                            />
                                            <Button style={{ width: 80 }} onClick={() => setPasswordVisible((prevState) => !prevState)}>
                                                {passwordVisible ? 'Hide' : 'Show'}
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                    <label>Password</label>
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="confirm"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Confirm password.',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The new password that you entered does not match!'));
                                                },
                                            })
                                        ]}
                                    >
                                        <Space direction="horizontal">
                                            <Input.Password
                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                placeholder="input password"
                                                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                                            />
                                            <Button style={{ width: 80 }} onClick={() => setPasswordVisible((prevState) => !prevState)}>
                                                {passwordVisible ? 'Hide' : 'Show'}
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                    <label>Confirm Password</label>
                                </Form.Item>
                                <Row className='flex item-center justify-center'>
                                    <Form.Item {...tailLayout}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={createAccountStyles.compareButton}
                                        >
                                            Create Account
                                        </Button>
                                    </Form.Item>
                                </Row>
                                <Row className="flex item-center justify-center">
                                    <Col span={10}>
                                        <p className="text-lg text-gray-500">Do you already have an account? You can <Login textLink={true} /></p>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>

                </section>
            </div>
        </Layout>
    )
}
