import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { Menu } from 'antd';
// import 'antd/dist/antd.css';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MenuOutlined } from '@ant-design/icons';
import { set } from 'react-hook-form';

const { SubMenu } = Menu;

export default function AdminNavBar() {
    let location = useLocation();

    const [current, setCurrent] = useState(null);

    const handleClick = e => {
        setCurrent(e);
    };

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
        switch (location.pathname) {
            case '/admin':
                setCurrent('reviews');
                break;
            case '/admin/admin-reviews':
                setCurrent('reviews');
                break;
            case '/admin/admin-quotes':
                setCurrent('quotes');
                break;
            case '/admin/admin-ccpa-requests':
                setCurrent('ccpa');
                break;
            case '/admin/admin-opt-out-requests':
                setCurrent('optout');
                break;
            default:
                break;
        }
    }, [location, current]);



    return (
        <Menu
            selectedKeys={[current]}
            mode="horizontal"
            onClick={handleClick}
        >
            <Menu.Item key="reviews" icon={<MenuOutlined />}>
                <Link to="/admin/admin-reviews">Reviews</Link>
            </Menu.Item>
            <Menu.Item key="quotes" icon={<MenuOutlined />}>
                <Link to="/admin/admin-quotes">Quotes</Link>
            </Menu.Item>
            <Menu.Item key="ccpa" icon={<SettingOutlined />}>
                <Link to="/admin/admin-ccpa-requests">CCPA</Link>
            </Menu.Item>
            <Menu.Item key="optout" icon={<SettingOutlined />}>
                <Link to="/admin/admin-opt-out-requests">Opt Out</Link>
            </Menu.Item>
        </Menu>
    )
}
