import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function AdvantagesOfExtendedCarWarranties() {
    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }
    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Are Extended Car Warranties Worth It? | Warranty Reviews</title>
                <meta property="og:title" content="Are Extended Car Warranties Worth It? | Warranty Reviews" />
                <meta name="description" content="Discover the advantages of an extended car warranty, including saving you money on costly car repairs and increasing the resale value of your automobile." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>The Advantages of <br />Extended Car Warranties</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>The Advantages of Extended Car Warranties</span></p></Col>
                </Row>
            </section>

            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>How do extended car warranties work? Is buying a car warranty worth it? What do extended car warranties cover? </p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>These are common questions about car warranties that pop up when purchasing a vehicle — especially for first-time car buyers. Cars are the second most expensive item most Americans buy, but skipping the car warranty coverage may not be the best way to cut costs.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Extended car warranties offer protection for your vehicle and added value if you ever decide to sell it.</p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/advantages-of-extended-car-warranties.png'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <p style={{ lineHeight: pLineHeight }}>While many car owners stick with the auto warranty that comes with the purchase of the car, aftermarket auto warranties offer unique advantages. In some cases, aftermarket auto warranties provide even more coverage than a standard car warranty.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Why Should You Purchase a Car Warranty?</h2>
                        <p style={{ lineHeight: pLineHeight }}><Link to={'/car-warranty-faq/how-do-car-warranties-work'}>Car warranties</Link> ensure that any issues or defects caused by the manufacturer's design or installation will be fixed by the car warranty provider. Typically, car warranties fall into two categories: manufacturer auto warranties and extended auto warranties.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}><strong>Manufacturer Car Warranty Benefits</strong></h3>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Manufacturer car warranties guarantee that if vehicle problems occur within a certain period of time or number of miles, the automaker will be responsible for the repairs. Manufacturer car warranties are built into the price of new vehicles and will decrease total cost of ownership by mitigating maintenance and repair costs beyond those associated with normal wear and tear. Manufacturer car warranties prevent you from having to pay out-of-pocket to fix defects and malfunctions caused by the vehicle's design.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>However, manufacturer car warranties last for only a set time or mileage limit. Typically manufacturer car warranties provide coverage for the first three years or the first 36,000 miles, meaning the warranty would cover needed repairs in the first three years you own your car or for the first 36,000 miles you drive it. This coverage remains in place until your car hits either of those two milestones, not both. For most car owners, the mileage limit comes first.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}><strong>Extended Car Warranty Benefits</strong></h3>
                        <p style={{ lineHeight: pLineHeight }}>Extended warranties are optional plans you can purchase to help cover the cost of certain repairs. They typically begin when your manufacturer warranty expires, but the two can sometimes overlap. Although aftermarket warranties are commonly referred to as “extended warranties” by consumers, they appear often as vehicle service contracts (VSCs) on paperwork.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>VSCs cover the costs of parts and labor according to the details outlined in the contract, and they also require regular monthly payments. Vehicle owners submit their claims to the VSC company, which reimburses them or bills their claims directly to the warranty company. Pre-existing issues and standard vehicle wear and tear are not typically covered by VSCs.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Choose Your Own Coverage Level</h2>
                        <p style={{ lineHeight: pLineHeight }}>One of the biggest benefits of extended car warranties is being able to choose the best <Link to={'/car-warranty-faq/what-do-car-warranties-cover'}>coverage level for your vehicle</Link> and budget. Some of the coverage types you can select are:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}><strong>Powertrain warranty</strong> covers the essential components that power the vehicle, including the driveshaft, engine and transmission.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Stated component warranty</strong> adds coverage for brakes, suspension and other features — in addition to the essential parts — to ensure you have protection for every item you want covered.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Exclusionary coverage</strong> includes everything in a stated component car warranty plan and beyond. This type of car warranty agreement lists items excluded from coverage instead of listing the included items.</p></li>
                        </ul>
                        <p style={{ lineHeight: pLineHeight }}>Before deciding on warranty coverage for your vehicle, <Link to={'https://warrantyreviews.com/'}>get a quote</Link> from car warranty specialists to ensure you have the proper protection.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Added Flexibility and Value for Your Vehicle</h2>
                        <p style={{ lineHeight: pLineHeight }}>Purchasing car warranties from third-party providers enables you to shop around and find the most affordable coverage options to fit your specific needs. Although extended car warranties can seem expensive, they help <Link to={'/car-warranty-faq/are-extended-car-warranties-worth-it'} >minimize the cost of major car repairs</Link> you may encounter in the future by having car owners pay monthly for coverage.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Many extended automobile warranties have a deductible. With a deductible, you will never have to pay more than a set amount no matter how expensive the parts and labor end up being. Extended car warranties also provide better flexibility, allowing you to take your vehicle to any authorized repair shop instead of to a specific auto dealership.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Some extended car warranties can even be transferred, allowing you to provide the new owner with comprehensive vehicle protection if you sell your vehicle in the future. This can add extra value to your vehicle and lead to a higher resale price. Vehicles with extended car warranty plans often sell for more and much faster than those without extended warranty coverage.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Get the Most Out of Your Car Warranty</h2>
                        <p style={{ lineHeight: pLineHeight }}>If your vehicle is generally reliable, you may not need an extended car warranty. Some car owners can end up paying more for the coverage than they get back in direct benefits. For this reason, some car owners opt to create an emergency fund for repairs to dip into when needed to pay for car repairs out-of-pocket.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>However, extended car warranties can offer you peace of mind if:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>You drive a luxury vehicle.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>You drive a high-mileage vehicle.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Your factory warranty has expired.</p></li>
                        </ul>
                        <p style={{ lineHeight: pLineHeight }}>In these scenarios, the potential cost savings on future car repairs can offset the monthly cost of the extended car warranty.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Enjoy peace of mind with an extended car warranty.</h2>
                        <p style={{ lineHeight: pLineHeight }}>Extended car warranties help you save money on expensive car repairs and can add to the resale value of your vehicle.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>How do you know what extended car warranty companies are trustworthy? Warranty Reviews gives you the resources to browse reviews from actual customers and explore the features of the best auto warranty companies. <Link to={'/car-warranty-reviews'} >Compare car warranty companies</Link> to find the right plan for you!</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
