import React from 'react'
import { Col, Layout, Row } from 'antd';
import adminOotOutRequestsStyles from './AdminOptOutRequests.module.css';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import useToken from '../useToken';
import NavBar from '../Shared/NavBar';
import AdminNavBar from '../Admin/AdminNavBar';
import AdminOptOutRequestTable from './children/AdminOptOutRequestTable.js';
import reqwest from "reqwest";
const { useState, useEffect } = React;
const { Header, Footer, Sider, Content } = Layout;

export default function AdminOptOutRequests() {
    const [isLoading, setIsLoading] = useState(false);
    const [optOutRequests, setOptOutRequests] = useState([]);
    const { token, setToken } = useToken();

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/admin/opt_out_requests`,
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "access-token": token.access_token,
                "client": token.client,
                "uid": token.uid,
            },
            type: "json"
        });
        setOptOutRequests(response.data.sort((a, b) => a.created_at < b.created_at ? 1 : -1))

        setIsLoading(false);
    };

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <Layout className={adminOotOutRequestsStyles.layoutStyle}>
            <section className={adminOotOutRequestsStyles.superHeader}>
                <AdminNavBar />
            </section>
            <Content className={adminOotOutRequestsStyles.contentWrapper}>
                {(isLoading ? <LoadingSpinner /> :
                    <AdminOptOutRequestTable optOutRequests={optOutRequests} />
                )}
            </Content>
        </Layout>
    )
}
