import React from 'react'
import { Table, Input, Button, Rate, Space, TableProps, Layout, Col, Row } from 'antd';
import { SearchOutlined, EditOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, LinkProps } from 'react-router-dom';
import adminReviewTableStyle from './AdminReviewTable.module.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
const { Header, Footer, Sider, Content } = Layout;

export default function AdminReviewTable(props) {
    dayjs.extend(utc);
    dayjs.extend(tz);

    const { reviews, approveReview, denyReview } = props;

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            width: '5%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by source"
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }
                            } />
                        <Space>
                            <Button
                                onClick={() => { confirm() }}
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}
                                type="danger"
                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.source.toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
            width: '6%',
            sorter: (a, b) => (a.company_name > b.company_name ? 1 : -1),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Company Name"
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }
                            } />
                        <Space>
                            <Button
                                onClick={() => { confirm() }}
                                type="primary"
                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}
                                type="danger"
                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.company_name.toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            width: '5%',
            sorter: (a, b) => (a.rating > b.rating ? 1 : -1),
            render: (id, record) => {
                return (
                    <div style={{ textAlign: 'center' }}>{record.rating}</div>
                )
            },
        },
        {
            title: 'Submitted',
            dataIndex: 'datetime',
            key: 'datetime',
            width: '10%',
            sorter: (a, b) => (a.datetime > b.datetime ? 1 : -1),
            render: (id, record) => {
                return (
                    dayjs(record.datetime).tz('America/Chicago').format('MM/DD/YYYY hh:mm:ss A') + ' CST'
                )
            },
        },
        {
            title: 'Review',
            dataIndex: 'content',
            key: 'content',
            width: '30%',

        },
        {
            title: 'Reviewer',
            dataIndex: 'full_name',
            key: 'user',
            width: '10%',
            render: (id, record) => {
                return (
                    <>{record.full_name}</>
                )
            }
        },
        {
            title: 'Status',
            key: 'status',
            width: '7%',
            dataIndex: 'status',
            filters: [
                {
                    text: 'Approved',
                    value: 'approved',
                },
                {
                    text: 'Trashed',
                    value: 'trashed',
                },
                {
                    text: 'Pending',
                    value: 'pending',
                },
            ],
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.status.startsWith(value),
            render: (status, record) => {
                if (status === 'approved') {
                    return (
                        <div className={adminReviewTableStyle.approved}><CheckOutlined className={adminReviewTableStyle.anticon} />{record.status}</div>
                    )
                } else if (status === 'trashed') {
                    return (
                        <div className={adminReviewTableStyle.denied}><DeleteOutlined className={adminReviewTableStyle.anticon} />{record.status}</div>
                    )
                } else {
                    return (
                        <div className={adminReviewTableStyle.pending}><EditOutlined className={adminReviewTableStyle.anticon} />{record.status}</div>
                    )
                }
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/admin/reviews/${record.id}`}>
                            <Button disabled={record.status === 'approved'} onClick={(e) => approveReview(record, e)} type="primary" icon={<CheckOutlined />}>Approve</Button>
                        </Link>
                        <Button style={{ backgroundColor: '#C42E1C', color: 'white' }} disabled={record.status === 'trashed'} onClick={(e) => { denyReview(record, e) }} danger type="primary" icon={<DeleteOutlined />}>Deny</Button>
                    </Space>
                )
            }
        }
    ]
    return (

        <Layout className={adminReviewTableStyle.layoutStyle}>
            <Header className={adminReviewTableStyle.header}>
                <Row>
                    <Col span={12}>
                        <h3>Approve or Deny Reviews</h3>
                    </Col>
                </Row>
            </Header>
            <Content className={adminReviewTableStyle.contentWrapper}>
                <Table
                    style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                    columns={columns}
                    dataSource={reviews}
                    rowKey="id"
                />
            </Content>
        </Layout>
    )
}
