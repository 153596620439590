import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';

const { Content } = Layout;
const { useBreakpoint } = Grid;


export default function CarWarrantyFaq() {
    let history = useHistory();

    const differenceBetweenCarWarrantyAndInsurance = () => {
        history.push(`/car-warranty-faq/difference-between-car-warranty-and-insurance`)
    }

    const advantagesOfExtendedCarWarranties = () => {
        history.push(`/car-warranty-faq/extended-car-warranty-benefits`)
    }
    const areCarWarrantiesWorthIt = () => {
        history.push(`/car-warranty-faq/are-extended-car-warranties-worth-it`)
    }

    const cancelCarWarranty = () => {
        history.push(`/car-warranty-faq/penalties-for-cancelling-car-warranty`)
    }

    const carWarrantyTypes = () => {
        history.push(`/car-warranty-faq/car-warranty-types`)
    }

    const chooseCarWarrantyPage = () => {
        history.push(`/car-warranty-faq/how-to-choose-car-warranty`)
    }

    const howDoCarWarrantiesWork = () => {
        history.push(`/car-warranty-faq/how-do-car-warranties-work`)
    }

    const transferCarWarranty = () => {
        history.push(`/car-warranty-faq/can-you-transfer-a-car-warranty`)
    }

    const whatDoCarWarrantiesCover = () => {
        history.push(`/car-warranty-faq/what-do-car-warranties-cover`)
    }
    const whenCarWarrantiesExpire = () => {
        history.push(`/car-warranty-faq/what-happens-when-car-warranty-expires`)
    }

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Car Warranty Facts & Answers to Car Warranty FAQ | Warranty Reviews</title>
                <meta property="og:title" content="Car Warranty Facts & Answers to Car Warranty FAQ | Warranty Reviews" />
                <meta name="description" content="Find out what an extended car warranty covers and if it's worth it. Learn more about the different types of car warranties and how to find the one that's right for you." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row>
                    <Col span={24}><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Resources &amp; FAQs</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
            </section>
            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/car-warranty-types'}><Image preview={false} src={'/assets/images/WR_DifferentTypesOfWarranties_Header.png'} /></Link>

                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/car-warranty-types'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Exploring Different Types of Car Warranties: A Comprehensive Guide for Vehicle Owners</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>The two most common types of car warranty are “bumper-to-bumper” and powertrain warranties. Learn the difference between the two and which is best for you.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => carWarrantyTypes()}>Read More</span>
                        </p>

                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/what-happens-when-car-warranty-expires'}><Image preview={false} src={'/assets/images/What-happens-when-your-manufacturer-car-warranty-expires.png'} /></Link>

                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/what-happens-when-car-warranty-expires'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>What Happens If Your Car Warranty Expires</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>If you have a factory car warranty, it is important to know what happens when it expires and how to replace the coverage you no longer have.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => whenCarWarrantiesExpire()}>Read More</span>
                        </p>

                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/difference-between-car-warranty-and-insurance'}><Image preview={false} src={'/assets/images/WarrantyVsInsurance_WR.png'} /></Link>

                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/difference-between-car-warranty-and-insurance'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Car Warranty vs. Car Insurance: What's the Difference?</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>Learn more about car warranties and car insurance policies, including the differences between what each covers, their duration, providers, and costs.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => differenceBetweenCarWarrantyAndInsurance()}>Read More</span>
                        </p>

                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/extended-car-warranty-benefits'}><Image preview={false} src={'/assets/images/advantages-of-extended-car-warranties.png'} /></Link>

                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/extended-car-warranty-benefits'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>The Advantages of Extended Car Warranties</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>These are common questions about car warranties that pop up when purchasing a vehicle — especially for first-time car buyers. Cars are the second most expensive item most Americans buy, but skipping the car warranty coverage may not be the best way to cut costs.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => advantagesOfExtendedCarWarranties()}>Read More</span>
                        </p>

                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/penalties-for-cancelling-car-warranty'}><Image preview={false} src={'/assets/images/car-warranty-chalkboard.jpg'} /></Link>

                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/penalties-for-cancelling-car-warranty'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Are There Penalties for <br />Canceling a Car Warranty?</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>Aside from a home, purchasing a vehicle is the largest expense most people undertake. You want to protect your purchase, so when the dealership suggests an extended car warranty, you agree. But what happens if you decide to cancel this extra protection for your vehicle? Can you get out of a car warranty agreement once you sign it? Will you be financially penalized for canceling an extended car warranty?
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => cancelCarWarranty()}>Read More</span>
                        </p>

                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/can-you-transfer-a-car-warranty'}><Image preview={false} src={'/assets/images/hands-shaking-with-keys.jpg'} /></Link>

                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/can-you-transfer-a-car-warranty'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Transferring a <br />Car Warranty to a New Car Owner</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>Selling a vehicle is a common occurrence. Drivers want to upgrade their car or change their mode of transportation as they move through different
                            stages of life — hello kids, hello minivan! What happens to the extended car warranty you paid for when a new owner takes possession of your vehicle? The simplest answer is —
                            the car warranty stays with the vehicle.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => transferCarWarranty()}>Read More</span>
                        </p>

                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/how-to-choose-car-warranty'}><Image preview={false} src={'/assets/images/man-in-car.jpg'} /></Link>

                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/how-to-choose-car-warranty'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Choosing A Car <br />Warranty Company</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>Be an informed consumer. What looks great at first glance might not always be the case. Researching policies, reading reviews and
                            asking the right questions will make the decision-making process smoother and instill confidence in your decision.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => chooseCarWarrantyPage()}>Read More</span>
                        </p>

                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/how-do-car-warranties-work'}><Image preview={false} src={'/assets/images/man-working-on-car.jpg'} /></Link>
                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/how-do-car-warranties-work'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>How Do Car<br /> Warranties Work</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>Car warranties protect owners from financial unknowns. While no one can predict when parts in your car will stop working
                            or if you will be involved in an accident, an extended warranty can relieve stress by covering
                            the costs to get you back in the driver's seat.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => howDoCarWarrantiesWork()}>Read More</span></p>
                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/what-do-car-warranties-cover'}><Image preview={false} src={'/assets/images/red-umbrella.jpg'} /></Link>
                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/what-do-car-warranties-cover'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>What Do Car <br />Warranties Cover</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>Most auto warranty companies offer different rates based on their level of coverage. Whether you are looking for
                            basic coverage on parts for a used vehicle, or to add to the manufacturer's full coverage for your new car,
                            there is a plan that meets your needs.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => whatDoCarWarrantiesCover()}>Read More</span>
                        </p>
                    </Col>
                </Row>
                <Row className={resourceFaqStyles.rowSpacing}>
                    <Col offset={2} span={8}>
                        <Link to={'/car-warranty-faq/are-extended-car-warranties-worth-it'}><Image preview={false} src={'/assets/images/office-work.jpg'} /></Link>
                    </Col>
                    <Col offset={1} span={12}>
                        <Link to={'/car-warranty-faq/are-extended-car-warranties-worth-it'}><h2 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Are Car Warranties <br />Worth It</h2></Link>

                        <p style={{ lineHeight: pLineHeight }}>All cars need repairs over time. As things break down the costs for repairs rise, auto owners are left with hefty bills.
                            That's why having a car warranty can mean the difference between saving money or skimping on repairs.
                            <br /><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500 hover:cursor-pointer' type="link" size="small" onClick={() => areCarWarrantiesWorthIt()}>Read More</span></p>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
