import React from 'react'
import { Table, Input, Button, Space, TableProps, Layout, Col, Row } from 'antd';
import { SearchOutlined, EditOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, LinkProps } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import adminCcpaRequestTableStyle from './AdminCcpaRequestTable.module.css';
const { Header, Footer, Sider, Content } = Layout;

export default function AdminCcpaRequestTable(props) {
    dayjs.extend(utc);
    dayjs.extend(tz);

    const { ccpaRequests } = props;

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            width: '15%',
            render: (id, record) => {
                return (
                    record.attributes.full_name
                )
            },
        },
        {
            title: 'Consumer Request',
            dataIndex: 'consumer_request',
            width: '6%',
            render: (id, record) => {
                return (
                    record.attributes.consumer_request
                )
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '8%',
            render: (id, record) => {
                return (
                    record.attributes.email
                )
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            width: '8%',
            render: (id, record) => {
                return (
                    record.attributes.phone_number
                )
            },
        },
        {
            title: 'CCPA Requests',
            dataIndex: 'ccpa_requests',
            width: '8%',
            render: (id, record) => {
                return (
                    record.attributes.type_of_ccpa_requests
                )
            },
        },
        {
            title: 'Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '10%',
            sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
            render: (id, record) => {
                return (
                    dayjs(record.attributes.created_at).tz('America/Chicago').format('MM/DD/YYYY hh:mm:ss A') + ' CST'
                )
            },
        }
    ]

    return (
        <Layout className={adminCcpaRequestTableStyle.layoutStyle}>
            <Header className={adminCcpaRequestTableStyle.header}>
                <Row>
                    <Col span={12}>
                        <h3>Approve or Deny Reviews</h3>
                    </Col>
                </Row>
            </Header>
            <Content className={adminCcpaRequestTableStyle.contentWrapper}>
                <Table
                    style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                    columns={columns}
                    dataSource={ccpaRequests}
                    rowKey="id"
                />
            </Content>
        </Layout>
    )
}
