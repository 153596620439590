import { Col, Layout, Row } from 'antd';
import React from 'react'
import reqwest from "reqwest";
import AdminReviewTable from './children/AdminReviewTable';
import adminReviewStyles from './AdminReview.module.css';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import useToken from '../useToken';
import NavBar from '../Shared/NavBar';
import AdminNavBar from '../Admin/AdminNavBar';
const { useState, useEffect } = React;
const { Header, Footer, Sider, Content } = Layout;

export default function AdminReviews() {
    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState([])
    const { token, setToken } = useToken();

    function approveReview(review, e) {
        e.preventDefault();
        console.log('approveReview', review);

        setIsLoading(true);
        reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/admin/reviews/${review.id}/approve`,
            method: 'put',
            type: 'json',
            contentType: 'application/json',
            headers: {
                "Accept": "application/json",
                "access-token": token.access_token,
                "client": token.client,
                "uid": token.uid,
            },
            success: (data) => {
                console.log('approveReview success', data)
                setIsLoading(false);
                setReviews(reviews.map(r => r.id === review.id ? { ...r, status: 'approved' } : r))
            },
            error: (err) => {
                console.log('approveReview error', err)
                setIsLoading(false);
            }
        })
    };

    function denyReview(review, e) {
        e.preventDefault();
        console.log('denyReview', review);
        setIsLoading(true);
        reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/admin/reviews/${review.id}/deny`,
            method: 'put',
            type: 'json',
            contentType: 'application/json',
            headers: {
                "Accept": "application/json",
                "access-token": token.access_token,
                "client": token.client,
                "uid": token.uid,
            },
            success: (data) => {
                console.log('denyReview success', data)
                setIsLoading(false);
                setReviews(reviews.map(r => r.id === review.id ? { ...r, status: 'trashed' } : r))
            },
            error: (err) => {
                console.log('denyReview error', err)
                setIsLoading(false);
            }
        })
    }

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/admin/reviews`,
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "access-token": token.access_token,
                "client": token.client,
                "uid": token.uid,
            },
            type: "json"
        });
        setReviews(response.reviews.sort((a, b) => a.datetime < b.datetime ? 1 : -1))

        setIsLoading(false);
    };

    useEffect(() => {
        customFetch({});
    }, []);


    return (
        <Layout className={adminReviewStyles.layoutStyle}>
            <section className={adminReviewStyles.superHeader}>
                <AdminNavBar />
            </section>
            <Content className={adminReviewStyles.contentWrapper}>
                {(isLoading ? <LoadingSpinner /> :
                    <AdminReviewTable reviews={reviews} approveReview={approveReview} denyReview={denyReview} />
                )}
            </Content>
        </Layout>
    )
}
