export const SET_COMPANY = 'SET_COMPANY';

export const initialCompanyState = {
    company: {},
}

export const addCompany = (company) => {
    return {
        type: SET_COMPANY,
        company
    }
}

export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY:
            localStorage.setItem('CompanyObject', JSON.stringify(action.company));
            return {
                ...state,
                company: action.company
            }
        default:
            return state;
    }
}