import React from 'react';
import NavBar from '../Shared/NavBar';
import { Divider } from 'antd';
export default function Preferences() {
    console.log('testing Preferences test')
    return (
        <div className="container mx-auto">
            <h1 className="text-lg">Preferences</h1>
            <Divider />
            <div className="flex flex-wrap">
                <p>Hello</p>
            </div>


        </div>
    )
}
