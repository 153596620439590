import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function ExploreDiffCarWarranty() {
    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }
    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Car Warranty Types | WarrantyReviews.com</title>
                <meta property="og:title" content="What Happens When Your Manufacturer Car Warranty Expires | Warranty Reviews" />
                <meta name="description" content="The two most common types of car warranty are “bumper-to-bumper” and powertrain warranties. Learn the difference between the two and which is best for you." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Exploring Different Types of Car Warranties: A Comprehensive Guide for Vehicle Owners</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>Exploring Different Types of Car Warranties</span></p></Col>
                </Row>
            </section>

            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>What is a car warranty? Although most vehicle owners have heard the phrase, few understand what car warranties are and the role they play in safeguarding you against expensive repair costs. In this comprehensive guide, we break down the complexities of car warranties, exploring the different types available, their benefits, and how they protect your vehicle.</p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/WR_DifferentTypesOfWarranties_Header.png'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Benefits of Extended Car Warranties</h2>
                        <p style={{ lineHeight: pLineHeight }}>Extended <Link to='/'>car warranties</Link>, also known as vehicle service contracts (VSCs), are contracts between vehicle owners and third-party warranty providers covering the costs of certain repairs. They are your financial safety net, defending against unforeseen mechanical or electrical malfunctions.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Should a problem arise, vehicle owners can submit their claims to the extended warranty company. Once warranty providers approve the claim, they will then pay for the costs of parts and labor, according to the details outlined in the contract. This procedure protects drivers from unexpected expenses, which is particularly helpful when repair costs escalate into thousands of dollars.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Extended warranty providers offer a range of service plans, covering issues from fuel systems and brakes to powertrain and computer systems. These warranties often incorporate 24/7 roadside assistance and rental car benefits, offering added support while your vehicle is in the repair shop.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>While there are a number of benefits with extended car warranties, the <Link to='/car-warranty-faq/difference-between-car-warranty-and-insurance'>coverage provided is not the same as car insurance</Link>. <Link to='/car-warranty-faq/extended-car-warranty-benefits'>Extended car warranties</Link> step in when specific parts fail due to manufacturing defects or normal wear and tear — serving as a valuable extension of your manufacturer warranty, and offering additional protection. However, car warranties will not cover damage resulting from a car accident, theft, or natural disaster the way car insurance does.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Types of Extended Car Warranties</h2>
                        <p style={{ lineHeight: pLineHeight }}>Extended car warranties allow you to <Link to='/car-warranty-faq/what-do-car-warranties-cover'>choose your coverage level</Link>. There are several types of car warranties, each tailored to different parts of your vehicle. The most common types are exclusionary warranties, powertrain warranties, and stated component warranties.</p>
                        <ul>
                            <li>
                                <p style={{ lineHeight: pLineHeight }}><strong>Exclusionary Warranties</strong><br />
                                    Exclusionary warranties provide the most expansive coverage, including air conditioning and electronics. They are also commonly referred to as “bumper-to-bumper” warranties. Repairs or replacements are covered by this warranty as long as the item is not on its exclusion list. This is an extensive coverage, so it can be more costly than other options.
                                </p>
                            </li>
                            <li>
                                <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Powertrain Warranties</strong><br />
                                    On the other hand, powertrain warranties concentrate on your vehicle's propulsion system, which includes the engine, driveshaft, axles, and transmission. They focus on the "heart" of your car, ensuring your mobility isn't compromised.
                                </p>
                            </li>
                            <li>
                                <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Stated Component Warranties</strong><br />
                                    A stated component warranty lists every individual component of a vehicle that is covered. Broader than powertrain warranties, they can include other car parts, such as brakes or suspension. These warranties function like a “coverage checklist,” allowing you to mark every item you want to protect.
                                </p>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Find the Car Warranty Right for You</h2>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Whether you've recently purchased a used car or have owned a vehicle for over five years, vehicle warranties can offer an extra layer of security for your vehicle. Understanding the range of warranty options available can help you choose the best car warranty to protect your investment, ensuring your peace of mind.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Which type of car warranty is right for you? Warranty Reviews helps you avoid the guessing game by giving you access to a list of reviews from actual customers. We help you compare the different features of the best auto warranty companies so you can find the best coverage for your needs.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
