import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Col, Image, Grid, Rate, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import homeStyles from '../Home.module.css';
import ReviewNew from '../../Review/ReviewNew';
import GetQuoteForm from '../../GetQuoteForm/GetQuoteForm';
import { useHistory } from 'react-router-dom';

const { useBreakpoint } = Grid;
export default function CompanyCard({ index, company, isLoading }) {

    let history = useHistory();

    const { xs, sm, md, lg } = useBreakpoint();
    let h2FontSize = '';
    let h2FontWeight = '';
    let h2LineHeight = '';
    let h3LineHeight = '';
    let marginBottom = '';
    let marginTop = '';
    let padding = '';
    let pFontSize = '';
    let pFontWeight = '';
    let pLineHeight = '';
    let textColor = '';

    if (xs) {
        h2FontSize = '2.286rem';
        h2FontWeight = '700';
        h2LineHeight = '2.286rem';
        h3LineHeight = '1.5rem';
        marginBottom = '20px';
        marginTop = '0px';
        padding = '30px';
        pFontSize = '1.25rem';
        pFontWeight = '600';
        pLineHeight = '1.714rem';
        textColor = '#606060';
    }

    if (sm) {
        h2FontSize = '2.286rem';
        h2FontWeight = '700';
        h2LineHeight = '2.286rem';
        h3LineHeight = '1.5rem';
        marginBottom = '20px';
        marginTop = '0px';
        padding = '30px';
        pFontSize = '1.25rem';
        pFontWeight = '600';
        pLineHeight = '1.65rem';
        textColor = '#606060';
    }

    if (md) {
        h2FontSize = '2.286rem';
        h2FontWeight = '700';
        h2LineHeight = '2.286rem';
        h3LineHeight = '1.5rem';
        marginBottom = '20px';
        marginTop = '0px';
        padding = '30px';
        pFontSize = '1.25rem';
        pFontWeight = '600';
        pLineHeight = '1.65rem';
        textColor = '#606060';
    }

    if (lg) {
        h2FontSize = '2.286rem';
        h2FontWeight = '700';
        h2LineHeight = '2.286rem';
        h3LineHeight = '1.5rem';
        marginBottom = '20px';
        marginTop = '0px';
        padding = '30px';
        pFontSize = '1.25rem';
        pFontWeight = '600';
        pLineHeight = '1.65rem';
        textColor = '#606060';
    }

    const companyPhoneButton = (company) => {
        if (company.company_name == 'CarShield') {
            return (
                <>
                    <Button className={`${homeStyles.callButton}`} icon={<PhoneOutlined />} type='primary' href='tel:800-498-0995'>800-498-0995</Button>
                </>
            )
        } else if (company.company_name == 'CARCHEX') {
            return (
                <>
                    <Button className={`${homeStyles.callButton}`} icon={<PhoneOutlined />} type='primary' href='tel:877-253-0058'>877-226-7119</Button>
                </>
            )
        } else {
            return (
                ''
            )
        }
    }

    const readReviews = (seoName) => {
        history.push(`/car-warranty-reviews/${seoName}`);
    }

    return (
        <>
            {((xs == true) && (sm == false) && (md == false) && (lg == false)) ?
                <Col key={company.id.toString()} style={{ padding: padding, border: '1px solid #606060', margin: '15px auto' }} xs={{ span: 23 }} className={`${homeStyles.cardWrapper}`}>
                    <Row className='justify-between'>
                        <Col xs={{ span: 24 }} style={{ textAlign: 'center' }} >
                            <Row>
                                <Col xs={{ span: 24 }} justify='center' className='space-y-4'>
                                    <Image style={{ maxWidth: '255px' }} preview={false} src={company.logo_svg} alt={company.company_name} />
                                </Col>
                                <Col xs={{ span: 24 }} justify='center' className='space-y-4'>
                                    <Rate allowHalf disabled defaultValue={company.aggregate_rating} style={{ color: '#EE9924', fontSize: 18 }} />
                                    <Link style={{ color: '#333333', textDecoration: 'underline', marginLeft: '15px' }} to={`/car-warranty-reviews/${company.seo_name}`}>
                                        Based on {company.number_of_approved_reviews} Reviews
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    {/* create a list of bullet points from the company.bullet_points array. */}
                                    <ul style={{ paddingLeft: '0' }} className={homeStyles.bulletPointListStyle}>
                                        {(isLoading == false) && company.bullet_points.slice(0, 3).map((element, index) => <li key={element.id.toString()}><p style={{ color: textColor, textAlign: 'left', fontSize: pFontSize, fontWeight: pFontWeight, lineHeight: pLineHeight }}>{element.description}</p></li>)}
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    <p style={{ color: textColor, fontSize: '1.15rem', fontWeight: pFontWeight, lineHeight: '1.65rem', textAlign: 'left' }}>{company.long_description}</p>
                                    <p><Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500" to={`/car-warranty-reviews/${company.seo_name}`}>{company.domain_name}</Link></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                            <Row>
                                <Col xs={{ span: 24 }} className='space-y-4' justify='center' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {companyPhoneButton(company)}
                                    <Button
                                        onClick={() => readReviews(company.seo_name)}
                                        className={`${homeStyles.readReviews}`}>Read Reviews</Button>
                                    <ReviewNew rankPlacementId={'2'} companyId={company.id} seoName={company.seo_name} companyLogo={company.logo_svg} isCompanyCard={true} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col> : ''
            }

            {((xs == false) && (sm == true) && (md == false) && (lg == false)) ?
                <Col key={company.id.toString()} style={{ padding: padding, border: '1px solid #606060', margin: '15px auto' }} xs={{ span: 23 }} className={`${homeStyles.cardWrapper}`}>
                    <Row className='justify-between'>
                        <Col xs={{ span: 24 }} style={{ textAlign: 'center' }} >
                            <Row>
                                <Col xs={{ span: 24 }} justify='center' className='space-y-4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Image style={{ maxWidth: '255px' }} preview={false} src={company.logo_svg} alt={company.company_name} />
                                    <Rate allowHalf disabled defaultValue={company.aggregate_rating} style={{ color: '#EE9924', fontSize: 18 }} />
                                    <Link style={{ color: '#333333', textDecoration: 'underline', marginLeft: '15px' }} to={`/car-warranty-reviews/${company.seo_name}`}>
                                        Based on {company.number_of_approved_reviews} Reviews
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    {/* create a list of bullet points from the company.bullet_points array. */}
                                    <ul style={{ paddingLeft: '0' }} className={homeStyles.bulletPointListStyle}>
                                        {(isLoading == false) && company.bullet_points.slice(0, 3).map((element, index) => <li key={element.id.toString()}><p style={{ color: textColor, textAlign: 'left', fontSize: pFontSize, fontWeight: pFontWeight, lineHeight: pLineHeight }}>{element.description}</p></li>)}
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    <p style={{ fontSize: '1.15rem', lineHeight: '1.65rem', textAlign: 'left' }}>{company.long_description}</p>
                                    <p><Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500" to={`/car-warranty-reviews/${company.seo_name}`}>{company.domain_name}</Link></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                            <Row>
                                <Col xs={{ span: 24 }} className='space-y-4' justify='center' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {companyPhoneButton(company)}
                                    <Button
                                        onClick={() => readReviews(company.seo_name)}
                                        className={`${homeStyles.readReviews}`}>Read Reviews</Button>
                                    <ReviewNew rankPlacementId={'2'} companyId={company.id} seoName={company.seo_name} companyLogo={company.logo_svg} isCompanyCard={true} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col> : ''
            }

            {((xs == true) && (sm == true) && (md == false) && (lg == false)) ?
                <Col key={company.id.toString()} style={{ padding: padding, border: '1px solid #606060', margin: '15px auto' }} xs={{ span: 22 }} className={`${homeStyles.cardWrapper}`}>
                    <Row className='justify-between'>
                        <Col xs={{ span: 24 }} style={{ textAlign: 'center' }} >
                            <Row>
                                <Col xs={{ span: 24 }} justify='center' className='space-y-4'>
                                    <Image style={{ maxWidth: '255px' }} preview={false} src={company.logo_svg} alt={company.company_name} />
                                    <Rate allowHalf disabled defaultValue={company.aggregate_rating} style={{ color: '#EE9924', fontSize: 18 }} />
                                    <Link style={{ color: '#333333', textDecoration: 'underline', marginLeft: '15px' }} to={`/car-warranty-reviews/${company.seo_name}`}>
                                        Based on {company.number_of_approved_reviews} Reviews
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    {/* create a list of bullet points from the company.bullet_points array. */}
                                    <ul className={homeStyles.bulletPointListStyle}>
                                        {(isLoading == false) && company.bullet_points.slice(0, 3).map((element, index) => <li key={element.id.toString()}><p style={{ color: textColor, textAlign: 'left', fontSize: pFontSize, fontWeight: pFontWeight, lineHeight: pLineHeight }}>{element.description}</p></li>)}
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    <p style={{ color: textColor, fontSize: '1.15rem', fontWeight: pFontWeight, lineHeight: '1.65rem', textAlign: 'left' }}>{company.long_description}</p>
                                    <p><Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500" to={`/car-warranty-reviews/${company.seo_name}`}>{company.domain_name}</Link></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} >
                            <Row>
                                <Col xs={{ span: 24 }} className='space-y-4' justify='center' style={{ textAlign: 'center' }}>
                                    {companyPhoneButton(company)}
                                    <Button
                                        onClick={() => readReviews(company.seo_name)}
                                        className={`${homeStyles.readReviews}`}>Read Reviews</Button>
                                    <ReviewNew rankPlacementId={'2'} companyId={company.id} seoName={company.seo_name} companyLogo={company.logo_svg} isCompanyCard={true} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col> : ''
            }

            {((md == true) || (lg == true)) &&
                <Col key={company.id.toString()} style={{ padding: padding, border: '1px solid #606060', margin: '15px auto' }} md={{ span: 22 }} lg={{ span: 18 }} className={`${homeStyles.cardWrapper}`}>
                    <Row className='justify-between'>
                        <Col md={{ span: 14 }} lg={{ span: 15 }} style={{ textAlign: 'left' }} >
                            <Row>
                                <Col lg={{ span: 24 }} justify='left'>
                                    <h2 style={{ color: textColor, fontSize: h2FontSize, fontWeight: h2FontWeight, lineHeight: h2LineHeight }}>{company.company_name}</h2>
                                    <hr style={{ color: '#CCCCCC' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    {/* create a list of bullet points from the company.bullet_points array. */}
                                    <ul className={homeStyles.bulletPointListStyle}>
                                        {(isLoading == false) && company.bullet_points.slice(0, 3).map((element, index) => <li key={element.id.toString()}><p style={{ color: textColor, textAlign: 'left', fontSize: pFontSize, fontWeight: pFontWeight, lineHeight: pLineHeight }}>{element.description}</p></li>)}
                                    </ul>

                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} justify='left'>
                                    <p style={{ color: textColor, fontSize: '1.15rem', fontWeight: pFontWeight, lineHeight: '1.65rem', textAlign: 'left' }}>{company.long_description}</p>
                                    <p><Link className="leading-snug hover:underline hover:underline-offset-2 text-blue-500" to={`/car-warranty-reviews/${company.seo_name}`}>{company.domain_name}</Link></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={{ span: 9 }} lg={{ span: 9 }}>
                            <Row>
                                <Col xs={{ span: 24 }} justify='center' className='space-y-4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Rate allowHalf disabled defaultValue={company.aggregate_rating} style={{ color: '#EE9924', fontSize: 18 }} />
                                    <Link style={{ color: '#333333', textDecoration: 'underline', marginLeft: '15px' }} to={`/car-warranty-reviews/${company.seo_name}`}>
                                        Based on {company.number_of_approved_reviews} Reviews
                                    </Link>
                                    <Image style={{ maxWidth: '255px' }} preview={false} src={company.logo_svg} alt="company logo" />
                                    <p>&nbsp;</p>
                                    {companyPhoneButton(company)}
                                    <Button
                                        onClick={() => readReviews(company.seo_name)}
                                        className={`${homeStyles.readReviews}`}>Read Reviews</Button>
                                    <ReviewNew rankPlacementId={'2'} companyId={company.id} seoName={company.seo_name} companyLogo={company.logo_svg} isCompanyCard={true} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            }
        </>
    )
}
