import { useRef, useEffect, useState } from "react";
import cn from "classnames";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./extended-fab.scss";
import StaticGetQuoteForm from "../../StaticGetQuoteForm/StaticGetQuoteForm";
import { set } from "react-hook-form";

const ExtendedFAB = ({ actions }) => {
    const [open, setOpen] = useState(false);

    const [display, setDisplay] = useState('none');

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [rightPosition, setRightPosition] = useState(90);
    const [modalWidth, setModalWidth] = useState(400);

    const triggerModal = (newStatus) => {
        setOpen(newStatus);
        if (newStatus == true) {
            setDisplay('block');
        } else {
            setDisplay('none');
        }
    }

    useEffect(() => {
        if (windowSize.current[0] > 500) {
            setRightPosition(90);
            setModalWidth(300);
            // Don't Trigger Modal if the current path is /get-warranty-quote
            // or /get-warranty-quote-thank-you

            if (window.location.pathname != '/get-warranty-quote' && window.location.pathname != '/get-warranty-quote-thank-you') {
                triggerModal(true);
            }

        } else {
            setRightPosition(0);
            setModalWidth(300);
            triggerModal(false);
        }
    }, []);

    console.log('windowSize', windowSize.current[0], windowSize.current[1])

    return (
        <>

            <Button
                type="primary"
                icon={<PlusOutlined />}
                className={cn("fab-container")}
                onClick={() => triggerModal(!open)}
                style={{ backgroundColor: '#C42E1C', border: 'none' }}
            >Get WR Partner Quote
            </Button>
            <div
                title="Basic Modal"
                style={{
                    position: 'fixed',
                    display: display,
                    bottom: 70,
                    right: rightPosition,
                    width: modalWidth,
                    height: 'max-content',
                    zIndex: 1000,
                    backgroundColor: 'white',
                    padding: 20,
                    border: '1px solid #ccc',
                    borderRadius: 4,
                    padding: 0
                }}
            >
                <StaticGetQuoteForm handleParentTriggerModal={(value) => {
                    triggerModal(value);
                }} />
            </div>
        </>
    );
};

export default ExtendedFAB;