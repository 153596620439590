import React from 'react';
const { useState, useEffect } = React;
import { userStore } from '../Providers/UserStore';
import useToken from '../useToken';
import reqwest from "reqwest";
import { useHistory } from 'react-router-dom';
import { Col, Layout, Row, Button, Form, Input, Modal, Radio, Rate, notification, Image, Alert, Space } from 'antd';
import reviewNewStyles from './ReviewNew.module.css';
import { reviewStore } from '../../context/Store';
import useStickyState from '../Shared/UseStickyState';

const { Header, Content } = Layout;
const { TextArea } = Input;

export default function ReviewNew({ rankPlacementId, companyId, seoName, companyLogo, isCompanyCard }) {

    let history = useHistory();
    const [state] = userStore();
    const { user } = state;
    const [company, setCompany] = useState({});
    const [componentDisabled, setComponentDisabled] = useState(true);
    const [review, setReview] = useState({
        rating: 0,
        content: '',
        customer: false,
    });
    const [showNotLoggedIn, setShowNotLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { token, setToken } = useToken();
    const [form] = Form.useForm();
    const [modalRankPlacementId, setModalRankPlacementId] = useState(rankPlacementId);
    const [modalCompanyId, setModalCompanyId] = useState(companyId);
    const [modalSeoName, setModalSeoName] = useState(seoName);
    const [modalCompanyLogo, setModalCompanyLogo] = useState(companyLogo);
    const [selectedModal, setSelectedModal] = useState(null);
    const [sticky_user, setUser] = useStickyState(user, 'UserObject');

    const customerOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ]

    const layout = {
        wrapperCol: {
            span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
            span: 20,
        },
    };

    const visible = reviewStore(state => state.toggled);
    const showReview = reviewStore(state => state.toggle);

    // Verify if user is logged in, hide modal and display error notification.
    const checkLogin = () => {
        if (!token || token.access_token === null) {
            showReview();
            setComponentDisabled(true);
            notification.error({
                message: 'Error',
                description: 'Please login to continue!'
            });
            return false
        } else {
            setComponentDisabled(false);
            return true;
        }
    }
    const createReview = async (params = {}) => {
        params = { company_id: modalCompanyId, ...params };
        setIsLoading(true);
        try {
            const response = await reqwest({
                url: `${process.env.REACT_APP_API_URL}/api/v1/companies/${modalSeoName}/reviews`,
                method: 'post',
                headers: {
                    "Content-Type": "application/json",
                    "access-token": token.access_token,
                    "content-type": "application/json",
                    "client": token.client,
                    "uid": token.uid
                },
                data: JSON.stringify({ 'review': params }),
                type: 'json'
            });
            setIsLoading(false);
            notification.success({
                message: 'Success',
                description: 'Review created successfully!'
            });
            showReview
            history.push(`/review-thank-you`);
        } catch (error) {
            setIsLoading(false);
            setReview(JSON.parse(error.response).review);
            notification.error({
                message: 'Error',
                description: JSON.parse(error.response).review.error_message
            });
            showReview
        }
    }

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        if (!token || token.access_token === null) {
            setComponentDisabled(true);
            setShowNotLoggedIn(true);
        }
        // const response = await reqwest({
        //     url: `${process.env.REACT_APP_API_URL}/api/v1/companies/${seoName}`,
        //     method: "get",
        //     headers: {
        //         "Accept": "application/json",
        //         "Content-Type": "application/json; charset=utf-8"
        //     },
        //     type: "json"
        // });

        // setCompany(response.company)

        setIsLoading(false);
    };

    const navigateToCreateAccount = () => {
        showReview(false);
        history.push("/create-account");
    };

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setReview({ ...review, [field.name[0]]: field.value })
        })
    }

    const onFinish = (values) => {
        sticky_user.user !== null && createReview(values);
    };

    const onFinishFailed = (values) => {
        notification.error({
            message: 'Error',
            description: 'Something went wrong'
        });
    };

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <>
            {isCompanyCard &&
                <Button style={{ margin: '15px auto' }} onClick={() => setSelectedModal(companyId)} className={reviewNewStyles.reviewButton} >Write a Review</Button>
            }
            {!isCompanyCard &&
                <Button onClick={() => setSelectedModal(companyId)} className={reviewNewStyles.compareButton} >Write a Review</Button>
            }
            <Modal
                id={companyId}
                key={companyId}
                open={selectedModal == companyId}
                centered
                className='flex justify-center items-center'
                disabled={componentDisabled}
                closable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={() => setSelectedModal(null)}>
                        Close
                    </Button>
                ]}
            >
                <>
                    <Header className={reviewNewStyles.header}>
                        <Row justify="center" align="middle">
                            <Col>
                                <Image preview={false} src={modalCompanyLogo} alt="company logo" />
                            </Col>
                        </Row>
                    </Header>
                    <Content id={companyId} align="center" className={reviewNewStyles.contentWrapper}>
                        <span id='review-form-wrapper'>
                            <Row id='review-form'>
                                <Col span={24} className='flex justify-center items-center'>
                                    <Form
                                        {...layout}
                                        colon={false}
                                        labelAlign="right"
                                        form={form}
                                        labelCol={{
                                            span: 4,
                                        }}
                                        wrapperCol={{
                                            span: 16,
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        onFieldsChange={onFieldsChange}
                                        requiredMark="true"
                                        initialValues={{
                                            'rating': 0,
                                            'content': '',
                                            'customer': false,
                                        }}
                                    >
                                        <Row className='flex'>
                                            <Col span={24}>
                                                <h4 className='text-2xl font-bold text-center mb-0'>Write A Review</h4>
                                            </Col>
                                        </Row>
                                        <div className='flex flex-col items-left justify-items-center'>
                                            <Form.Item>
                                                <Form.Item
                                                    name="rating"
                                                    required
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please choose a rating!',
                                                        },
                                                    ]}
                                                >
                                                    <Rate allowHalf style={{ color: '#EE9924', fontSize: 25 }} />

                                                </Form.Item>
                                                <label>Rating</label>
                                            </Form.Item>
                                            <Form.Item>
                                                <Form.Item
                                                    name="content"
                                                    required
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please write a review!',
                                                        }
                                                    ]}
                                                >
                                                    <TextArea
                                                        rows={4}
                                                    />
                                                </Form.Item>
                                                <label>Write your Review</label>
                                            </Form.Item>
                                            <Form.Item>
                                                <Form.Item
                                                    name='customer'
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Please select whether you are a customer or not!',
                                                        }
                                                    ]}
                                                >
                                                    <Radio.Group
                                                        name='customer'
                                                        options={customerOptions}
                                                    />
                                                </Form.Item>
                                                <label>Are you a past or current customer of this warranty company?</label>
                                            </Form.Item>
                                            {(sticky_user.user == null && <Row justify="center" align="middle">
                                                <Col span={24}>
                                                    <Space direction="vertical" size="large">
                                                        <Alert message="Please login to continue!" type="error" showIcon />
                                                    </Space>
                                                </Col>
                                            </Row>)}
                                            <Row className='flex item-center justify-center'>
                                                <Form.Item {...tailLayout}>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className={reviewNewStyles.compareButton}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </Row>
                                        </div>

                                    </Form>
                                </Col>
                            </Row>
                            <Row className='flex item-center justify-center'>
                                {(sticky_user.user == null) &&
                                    <Col span={14}><p className='text-lg mt-5 text-center'>Don't have an account? You can <Button type='link' onClick={() => navigateToCreateAccount()}><span className='text-lg leading-snug hover:underline hover:underline-offset-2 text-blue-500'>create a new account.</span></Button></p></Col>
                                }
                            </Row>
                        </span>

                    </Content>
                </>
            </Modal >
        </>
    )
}