import React from 'react'
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function CanYouTransferACarWarranty() {

    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }
    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Transferring a Car Warranty to a New Car Owner | Warranty Reviews</title>
                <meta property="og:title" content="Transferring a Car Warranty to a New Car Owner | Warranty Reviews" />
                <meta name="description" content="While you can't transfer a car warranty from one car to another, you can transfer a warranty to a new owner. Learn how." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Can You Transfer a Car Warranty?</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>Can You Transfer a Car Warranty?</span></p></Col>
                </Row>
            </section>

            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>Selling a vehicle is a common occurrence. Drivers want to upgrade their car or change their mode of transportation as they move through different stages of life — hello kids, hello minivan! What happens to the extended car warranty you paid for when a new owner takes possession of your vehicle? The simplest answer is — the car warranty stays with the vehicle.</p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/hands-shaking-with-keys.jpg'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Car Warranty Terms</h2>
                        <p style={{ lineHeight: pLineHeight }}>When the factory warranty on your vehicle expires, purchasing an <a target='_blank' href="https://warrantyreviews.com/car-warranty-faq/how-do-car-warranties-work">extended car warranty</a> can continue your coverage and offer peace of mind against unexpected repairs. Before delving into what happens to your warranty when you sell your car, let's briefly touch on why it can be valuable to purchase an extended car warranty in the first place.</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>An extended car warranty covers the cost of repairing or replacing parts on your vehicle that break down over time.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Warranties can be extremely valuable if your car isn't completely reliable and you don't have the money to repair it right away.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>If you purchase a pre-owned car from a dealer, an extended warranty offers peace of mind, especially if you're uncertain about the vehicle's reliability.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Car Warranty Transfer</h2>
                        <p style={{ lineHeight: pLineHeight }}>It is fairly standard in the auto industry to tie new car warranties to the vehicle identification number, or VIN, often etched on a sticker inside the driver's door jamb. Sometimes the VIN will appear on the dashboard or the front of the car frame. This is the ID number linking your vehicle to its assigned warranty coverage.</p>
                        <p style={{ lineHeight: pLineHeight }}>The new car warranty is valid for the entire warranty term regardless of who owns the vehicle. If you buy the car, the manufacturer's warranty now belongs to you. Pay close attention to when the warranty expires; it could expire after a certain number of miles driven or a certain number of years — whichever comes first. For example, a car warranty three year plan is a popular option, with many new car warranties lasting for “<a target='_blank' href="https://www.caranddriver.com/research/a31608083/factory-warranty/">3 years or 36,000 miles.</a>”</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 style={{ lineHeight: h2LineHeight }}>You purchased a pre-owned vehicle — here's how to claim the extended warranty coverage.</h3>
                        <p style={{ lineHeight: pLineHeight }}>Purchasing a used vehicle is always a bit stressful because you do not know how reliable it will be. That's why cars and trucks with pre-existing extended car warranties are attractive and offer a bit more peace of mind. Here's what to do once you finalize your automobile purchase.</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Remind the current owner of the car to contact the warranty company or dealership and initiate a transfer of the extra coverage.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Alternatively, the seller can send a letter to the warranty provider letting them know about the sale.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>You typically have to initiate the transfer within 30 days of the vehicle being sold to a new owner. </p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Tips about transferring your car warranty</h2>
                        <p style={{ lineHeight: pLineHeight }}>Many sellers mention any included warranties when marketing their vehicles. Pay close attention to the <strong>type</strong> of warranty that is transferring to the vehicle you purchase.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Transferring manufacturer warranties</h3>
                        <ul>
                            <ul><p style={{ lineHeight: pLineHeight }}>Manufacturer warranties transfer to a new owner automatically because they are tied to the car's VIN number. Be sure to note when the warranty coverage expires. </p></ul>
                            <ul><p style={{ lineHeight: pLineHeight }}>In most cases, no documentation is required.</p></ul>
                            <ul><p style={{ lineHeight: pLineHeight }}>You might need to send proof of a title change to the manufacturer before being approved for a claim.</p></ul>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Transferring extended warranties</h3>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Extended warranties offer coverage when the manufacturer warranty runs out and are purchased separately — often from the dealership.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>There may be a small administrative fee to transfer the policy (usually $50 or less). </p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Only the original purchaser can transfer the policy, and the manufacturer's warranty (if still valid) must also be transferred. </p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Rules may vary from state to state, but generally you have just 30 days to initiate a transfer of the policy. </p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Can you sell your car warranty to someone? </h3>
                        <p style={{ lineHeight: pLineHeight }}>If you're wondering if it's possible to make a little extra money by selling your car warranty to someone else, the answer is no. Car warranty terms dictate the extra insurance coverage stays with the vehicle it was assigned to upon purchase.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Can your car warranty transfer to a new car? </h3>
                        <p style={{ lineHeight: pLineHeight }}>Warranties are never transferred from vehicle to vehicle — they stay paired with their assigned vehicle. On the off chance the person buying your vehicle doesn't want to take over your extended coverage policy, be sure to reach out to the warranty company as soon as possible to cancel the coverage. You may be entitled to a partial refund! </p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Protect Yourself Against Untrustworthy Extended Car Warranty Service Providers</h2>
                        <p style={{ lineHeight: pLineHeight }}>When you purchase a vehicle and <Link to={'/car-wrranty-faq/how-to-choose-car-warranty'}>choose a car warranty plan</Link>, you are not only avoiding paying for a trip to the mechanic out of pocket, but also adding to the value of your car or truck should you choose to sell it one day. Buyers prefer a vehicle that still offers some warranty protection.</p>
                        <p style={{ lineHeight: pLineHeight }}>How do you know what extended car warranty companies are trustworthy? Warranty Reviews can help you with your car warranty transfer plans. We give you the resources to browse reviews from actual customers and explore the features of the best warranty companies. <Link to={'/car-warranty-reviews'}>Compare warranty companies</Link> to find the right plan for you!</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
