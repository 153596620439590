import React from 'react';
import MetaTags from 'react-meta-tags';
import NavBar from '../Shared/NavBar';
import { Col, Divider, Grid, Layout, Row } from 'antd';
import CompanyCard from '../Home/children/CompanyCard';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner'
import reqwest from "reqwest";
import quoteThankYouPageStyles from './QuoteFunnelThankYouPage.module.css';
const { useBreakpoint } = Grid;
const { Header, Footer, Sider, Content } = Layout;
const { useState, useEffect } = React;

export default function QuoteFunnelThankYouPage() {

    const [componantList, setComponantList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await reqwest({
            url: `${process.env.REACT_APP_API_URL}/api/v1/companies`,
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            type: "json"
        });

        setCompanyList(response.companies.sort((a, b) => a.aggregate_rating < b.aggregate_rating ? 1 : -1));

        let list = response.companies.sort((a, b) => (a.aggregate_rating < b.aggregate_rating) ? 1 : -1);

        setComponantList(list.map((company, index) => {
            return (
                <CompanyCard key={company.id.toString()} index={index} company={company} isLoading={isLoading} />
            )
        }));

        setIsLoading(false);
    };

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
    }

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <Layout className={quoteThankYouPageStyles.layoutStyle}>
            <MetaTags>
                <title>Extended Car Warranty Companies Request A Quote| Warranty Reviews</title>
                <meta property="og:title" content="Extended Car Warranty Companies List & Reviews | Warranty Reviews" />
                <meta name="description" content="Browse our list of extended car warranty companies online. Compare coverage options and costs. Read customer reviews. Get an online quote." />
            </MetaTags>
            <section className={quoteThankYouPageStyles.headerStyle}>
                <Row className='mb-3'>
                    <Col span={24}><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Extended Car Warranty Options</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={quoteThankYouPageStyles.dividerStyle} /></Col>
                </Row>
            </section>
            <Row className='flex item-center justify-center mt-5' style={{ textAlign: 'center', justifyContent: 'center', marginTop: '50px' }}>
                <Col span={14}>
                    <span className='text-center text-lg font-light'>
                        Your car warranty quote request has been submitted. A WarrantyReviews.com partner will call or email you with additional information.
                    </span>
                </Col>
            </Row>
            <Row justify="center" align="middle">
                {(isLoading ? <LoadingSpinner /> :
                    (componantList.length > 0 ? componantList : <p>No companies found</p>)
                )}
            </Row>
        </Layout>
    )
}
