import React from 'react'
import NavBar from '../Shared/NavBar';
import accessibilityPolicyStyles from './AccessibilityPolicy.module.css';
import LoadingSpinner from './../Shared/LoadingSpinner/LoadingSpinner';
import { Col, Layout, Row, Button, Form, Input, Select, notification, Image, Divider } from 'antd';
const { Header, Footer, Sider, Content } = Layout;


export default function AccessibilityPolicy() {
    return (
        <Layout className={accessibilityPolicyStyles.layoutStyle}>
            <Header className={accessibilityPolicyStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <h1>Accessibility Statement</h1>
                </Row>
            </Header>
            <Content id='page-body' className='container mx-auto'>
                <div id='accessibility-statement'>
                    <p>Warranty Reviews is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone, and applying the relevant accessibility standards.</p>

                    <h2>Measures to support accessibility</h2>
                    <p>Warranty Reviews takes the following measures to ensure accessibility of Warranty Reviews website:</p>

                    <ul className={accessibilityPolicyStyles.listStyle}>
                        <li>Conformance status.</li>
                    </ul>

                    <p>The Web Content Accessibility Guidelines (WCAG) defines requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA.</p>
                    <p>Warranty Reviews is partially conformant with WCAG 2.1 level AA.</p>
                    <p>Partially conformant means that some parts of the content do not fully conform to the accessibility standard.</p>

                    <h2>Feedback</h2>
                    <p>We welcome your feedback on the accessibility of Warranty Reviews website. Please let us know if you encounter accessibility barriers on Warranty Reviews. <a className="leading-snug hover:underline hover:underline-offset-2 text-blue-500" href="mailto: customerservice@warrantyreviews.com?subject=Accessibility Feeback">Contact us.</a></p>

                    <h2>Compatibility with browsers and assistive technology</h2>
                    <p>Warranty Reviews. is designed to be compatible with the following assistive technologies:</p>

                    <ul className={accessibilityPolicyStyles.listStyle}>
                        <li>Safari browser v.12. with Voice Over on MacOS</li>
                    </ul>

                    <p>Warranty Reviews is not compatible with:</p>

                    <ul className={accessibilityPolicyStyles.listStyle}>
                        <li>Browsers older than 3 major versions.</li>
                        <li>Mobile operating systems older than 5 years.</li>
                        <li>Technical specifications</li>
                    </ul>

                    <p>Accessibility of Warranty Reviews relies on the following technologies to work with the particular combination of web browser and any assistive technologies or plugins installed on your computer:</p>

                    <ul className={accessibilityPolicyStyles.listStyle}>
                        <li>HTML</li>
                        <li>WAI-ARIA</li>
                        <li>CSS</li>
                        <li>JavaScript</li>
                    </ul>

                    <p>These technologies are relied upon for conformance with the accessibility standards used.</p>

                    <h2>Assessment approach</h2>

                    <p>Warranty Reviews assessed the accessibility of Warranty Reviews website by the following approaches:</p>

                    <ul className={accessibilityPolicyStyles.listStyle}>
                        <li>Self-evaluation</li>
                        <li>External evaluation</li>
                    </ul>
                </div>
            </Content>
        </Layout>
    )
}
