import React, { useState, useRef, useEffect } from "react";
import "./styles/App.less";
import 'antd/dist/antd.css';
import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import { FcMoneyTransfer, FcBusinessman, FcCamera, FcFullTrash } from "react-icons/fc";
import { PrivateRoute } from "./components/Shared/PrivateRoute";
import AccessibilityPolicy from "./components/AccessibilityStatement/AccessibilityPolicy";
import AdvantagesOfExtendedCarWarranties from "./components/CarWarrantyFaq/AdvantagesOfExtendedCarWarranties";
import Admin from "./components/Admin/Admin";
import AreCarWarrantiesWorthIt from "./components/CarWarrantyFaq/AreCarWarrantiesWorthIt";
import AreTherePenaltiesForCanceling from "./components/CarWarrantyFaq/AreTherePenaltiesForCanceling";
import CanYouTransferACarWarranty from "./components/CarWarrantyFaq/CanYouTransferACarWarranty";
import CarWarrantyFaq from "./components/CarWarrantyFaq/CarWarrantyFaq";
import CcpaRequest from "./components/HelpSupport/DisclosureRequest/CcpaRequest";
import CcpaThankYou from "./components/HelpSupport/CcpaThankYou";
import ChoosingACarWarrantyCompany from "./components/CarWarrantyFaq/ChoosingACarWarrantyCompany";
import CompaniesIndex from "./components/Companies/CompaniesIndex";
import CompanyShow from "./components/Company/CompanyShow";
import CreateAccount from "./components/CreateAccount/CreateAccount";
import DifferenceBetweenCarWarrantyAndInsurance from "./components/CarWarrantyFaq/DifferenceBetweenCarWarrantyAndInsurance";
import ExtendedFAB from "./components/Shared/ExtendedFAB/ExtendedFAB";
import GlobalFooter from "./components/Shared/GlobalFooter";
import Home from "./components/Home/Home";
import HowDoCarWarrantiesWork from "./components/CarWarrantyFaq/HowDoCarWarrantiesWork";
import Login from "./components/Login/Login";
import NavBar from "./components/Shared/NavBar";
import NoMatch from "./components/NoMatch/NoMatch";
import OptOutRequest from "./components/HelpSupport/OptOutRequest/OptOutRequest";
import Preferences from "./components/Preferences/Preferences";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import QuoteFunnelForm from "./components/QuoteFunnelForm/QuoteFunnelForm";
import QuoteFunnelThankYouPage from "./components/QuoteFunnelForm/QuoteFunnelThankYouPage";
import QuoteThankYouPage from "./components/GetQuoteForm/QuoteThankYouPage";
import ReviewNew from "./components/Review/ReviewNew";
import ReviewThankYou from "./components/ReviewThankYou/ReviewThankYou";
import User from "./components/User/User";
import useToken from './components/useToken';
import WhatDoCarWarrantiesCover from "./components/CarWarrantyFaq/WhatDoCarWarrantiesCover";
import WhenCarWarrantiesExpire from "./components/CarWarrantyFaq/WhenCarWarrantiesExpire";
import ExploreDiffCarWarranty from "./components/CarWarrantyFaq/ExploreDiffCarWarranty";


function App() {
  const { token, setToken } = useToken();

  const actions = [
    { label: "Request A Quote", icon: <FcMoneyTransfer />, onClick: console.log },
    { label: "Profile", icon: <FcBusinessman />, onClick: console.log },
    { label: "Picture", icon: <FcCamera />, onClick: console.log },
    { label: "Trash", icon: <FcFullTrash />, onClick: console.log },
  ];

  if (!token) {
    return <Login setToken={setToken} />; // <-- this is the only place where we use setToken
  }

  return (
    <>
      <div className="App">
        <NavBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/user/:id" exact render={(props) => (
            <User {...props} token={token} />
          )} />
          <Route path="/preferences" exact render={(props) => (
            <Preferences {...props} token={token} />
          )} />
          <Route path="/car-warranty-reviews" exact render={(props) => (
            <CompaniesIndex {...props} />
          )} />
          <Route path="/car-warranty-reviews/:seo_name" exact render={(props) => (
            <CompanyShow {...props} token={token} />
          )} />
          <Route path="/car-warranty-faq" exact render={(props) => (
            <CarWarrantyFaq {...props} />
          )} />
          <Route path="/car-warranty-faq/are-extended-car-warranties-worth-it" exact render={(props) => (
            <AreCarWarrantiesWorthIt {...props} />
          )} />
          <Route path="/car-warranty-faq/can-you-transfer-a-car-warranty" exact render={(props) => (
            <CanYouTransferACarWarranty {...props} />
          )} />
          <Route path="/car-warranty-faq/car-warranty-types" exact render={(props) => (
            <ExploreDiffCarWarranty {...props} />
          )} />
          <Route path="/car-warranty-faq/difference-between-car-warranty-and-insurance" exact render={(props) => (
            <DifferenceBetweenCarWarrantyAndInsurance {...props} />
          )} />
          <Route path="/car-warranty-faq/extended-car-warranty-benefits" exact render={(props) => (
            <AdvantagesOfExtendedCarWarranties {...props} />
          )} />
          <Route path="/car-warranty-faq/how-do-car-warranties-work" exact render={(props) => (
            <HowDoCarWarrantiesWork {...props} />
          )} />
          <Route path="/car-warranty-faq/how-to-choose-car-warranty" exact render={(props) => (
            <ChoosingACarWarrantyCompany {...props} />
          )} />
          <Route path="/car-warranty-faq/penalties-for-cancelling-car-warranty" exact render={(props) => (
            <AreTherePenaltiesForCanceling {...props} />
          )} />
          <Route path="/car-warranty-faq/what-do-car-warranties-cover" exact render={(props) => (
            <WhatDoCarWarrantiesCover {...props} />
          )} />
          <Route path="/car-warranty-faq/what-happens-when-car-warranty-expires" exact render={(props) => (
            <WhenCarWarrantiesExpire {...props} />
          )} />
          <Route path="/get-warranty-quote" exact render={(props) => (
            <QuoteFunnelForm {...props} />
          )} />
          <Route path="/get-warranty-quote-thank-you" exact render={(props) => (
            <QuoteFunnelThankYouPage {...props} />
          )} />
          <Route path='/help-support/ccpa-request' exact render={(props) => (
            <CcpaRequest {...props} />
          )} />
          <Route path='/help-support/opt-out-request' exact render={(props) => (
            <OptOutRequest {...props} />
          )} />
          <Route path='/help-support/thankyou' exact render={(props) => (
            <CcpaThankYou {...props} />
          )} />
          <Route path="/quote-request-thank-you" exact render={(props) => (
            <QuoteThankYouPage {...props} />
          )} />
          <Route path='/review-thank-you' exact render={(props) => (
            <ReviewThankYou {...props} />
          )} />
          <Route path="/create-account" exact component={CreateAccount} />
          <Route path="/privacy_policy" exact component={PrivacyPolicy} />
          <Route path="/accessbility_statement" exact component={AccessibilityPolicy} />
          <PrivateRoute exact path="/company/:company_id/review/new" component={ReviewNew} />
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="/login" exact component={Login} />
          <Route path="/default" render={() => <Redirect to="/" />} />
          <Route component={NoMatch} />
        </Switch>
      </div>
      <GlobalFooter></GlobalFooter>
      <ExtendedFAB />
    </>
  );
}

export default App;
