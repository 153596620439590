import React from 'react';

const CompanyStore = React.createContext();
CompanyStore.displayName = 'CompanyProvider Store';

export const useStore = () => React.useContext(CompanyStore);

export const CompanyStoreProvider = ({ children, initialState, reducer }) => {
    const [globalState, dispatch] = React.useReducer(reducer, initialState);

    return (
        <CompanyStore.Provider value={[globalState, dispatch]}>{children}</CompanyStore.Provider>
    );
}