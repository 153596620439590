import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { CookiesProvider } from "react-cookie";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  useQuery,
  gql
} from "@apollo/client";
import { CompanyStoreProvider } from "./components/Providers/CompanyStore";
import { UserStoreProvider } from './components/Providers/UserStore';
import { initialCompanyState, companyReducer } from './components/Providers/CompanyReducer';
import { initialUserState, userReducer } from "./components/Providers/UserReducer";

import { setContext } from '@apollo/client/link/context';

import App from "./App";
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import ScrollToTop from "./components/Shared/ScrollToTop";


const rootElement = document.getElementById("root");

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const config = {
  apiKey: 'hbp_iFEVbURPylLnJsGxCvFftafdcaWa4r0P297v',
  environment: 'production',
  revision: 'git SHA/project version'
}

const honeybadger = Honeybadger.configure(config)

const getAuthtoken = () => {
  const cookie = document.cookie.split(';').find(c => c.trim().startsWith('authToken='));
  return cookie ? decodeURI(cookie.split('=')[1]) : null;
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('authToken');
  const token = getAuthtoken();
  // return the headers to the context so httpLink can read them
  const authorization = token ? `authorization:  ${token}` : null;
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `${token}` } : {})
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <CookiesProvider>
            <UserStoreProvider initialState={initialUserState} reducer={userReducer}>
              <CompanyStoreProvider initialState={initialCompanyState} reducer={companyReducer}>
                <ScrollToTop>
                  <App />
                </ScrollToTop>
              </CompanyStoreProvider>
            </UserStoreProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ApolloProvider>
    </HoneybadgerErrorBoundary>
  </React.StrictMode>,
  rootElement
);
