import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;

const { useBreakpoint } = Grid;

export default function WhatDoCarWarrantiesCover() {
    let history = useHistory();
    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }

    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>What Do Car Warranties Cover? | Warranty Reviews</title>
                <meta property="og:title" content="What Do Car Warranties Cover? | Warranty Reviews" />
                <meta name="description" content="Gain a better understanding of the different types of car warranties and what extended car warranties cover to help you select a policy that covers all your needs." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>What Do Car <br />Warranties Cover</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>What Do Car Warranties Cover</span></p></Col>
                </Row>
            </section>
            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>

                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>Most auto warranty companies offer different rates based on their level of coverage. Whether you are looking for
                            basic coverage on parts for a used vehicle, or to add to the manufacturer's full coverage for your new car,
                            there is a plan that meets your needs.
                        </p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/red-umbrella.jpg'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <p style={{ lineHeight: pLineHeight }}>When selecting car warranty coverage, knowledge is power. Most auto warranty companies offer different rates based on their level of coverage. And policies only cover the replacement and repair of certain parts. Understanding the differences between the types of plans will help you select a policy that protects you and covers all your needs.</p>
                        <p style={{ lineHeight: pLineHeight }}>Many plans start with one of the two most common types of warranties: bumper-to-bumper and powertrain. A new car or bumper-to-bumper warranty will provide the most comprehensive coverage, including the air conditioning and electronics. A powertrain warranty focuses simply on the vehicle’s propulsion system, which is usually a car’s most significant and most expensive components.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>General Car Warranty Coverage</h2>
                        <p style={{ lineHeight: pLineHeight }}>The following is a list of parts that are commonly covered under most warranty plans:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Fuel system</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Brakes</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Powertrain (the engine, driveshaft, axles, and transmission)</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Gearbox</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Heating and air conditioning</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Steering and ignition</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Electrical and computer systems (including in-car media and entertainment systems)</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>What is a Bumper-to-Bumper Warranty?</h2>
                        <p style={{ lineHeight: pLineHeight }}>Car drivers wanting maximum coverage for their vehicles can select a bumper-to-bumper warranty. Sometimes called an exclusionary warranty, it has a broader coverage for repairs to most parts between your vehicle’s front and rear bumpers. It can be used to extend a factory warranty or on a used vehicle. But don’t let the name fool you — it does not cover the bumpers themselves.</p>
                        <p style={{ lineHeight: pLineHeight }}>Common bumper-to-bumper plans last for three years or 36,000 miles. However, other options are available depending on the provider.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>What Car Warranties Do Not Cover</h2>
                        <p style={{ lineHeight: pLineHeight }}>All car warranties are limited to some extent. Not every type of repair is covered, and it only lasts a specified amount of time. </p>
                        <p style={{ lineHeight: pLineHeight }}>As you examine which type of coverage is best for you, it is a good idea to understand the terms of your plan. Knowing what is not covered will prevent an unexpected denial of benefits or even void your warranty.</p>
                        <p style={{ lineHeight: pLineHeight }}>The following is a list of issues that are generally not part of car warranty coverage without an add on:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Normal wear and tear of various parts, such as headlights, windshield wiper blades, clutches, brakes, brake pads, etc.</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Damage from using the incorrect type of fuel</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Interior damage caused by spills, holes in car upholstery, loss of buttons or knobs, and broken plastic pieces</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Car body paint damage, including nicks, scratches, and chips in the paint</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Accidental damage from collisions or fender benders that have caused dents, broken glass, smashed grills, or other destruction</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Acts of weather, including damage from high winds, hail, or dust</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Making non-covered modifications to the car, including the use of non-factory parts, replacing the engine type, or rolling back the odometer</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Vandalism or other forms of defacement, like punctured tires or key scratches</p></li>
                            <li><p style={{ lineHeight: pLineHeight }}>Reckless driving, speeding, or neglecting routine car maintenance</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>All About Add-Ons</h2>
                        <p style={{ lineHeight: pLineHeight }}>An add-on is a type of expanded coverage that gives consumers more protection, but at a cost. Mostly used by auto dealers, add-ons are an option for extending the total coverage time beyond its original expiration date. </p>
                        <p style={{ lineHeight: pLineHeight }}>As an example, a new auto with a three-year warranty could be expanded with an add-on to last five or more years, with the consumer paying for the additional length of the warranty.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Consequential Loss and Betterment</h2>
                        <p style={{ lineHeight: pLineHeight }}>Another term to be familiar with when selecting a car warranty is consequential loss and betterment.</p>
                        <p style={{ lineHeight: pLineHeight }}>Not featured in every plan, it is applied when a vehicle part that isn’t directly covered by the warranty fails and causes damage to another part that is covered. If this occurs, the existing warranty handles the replacement and repair costs.</p>
                        <p style={{ lineHeight: pLineHeight }}>Betterment clauses involve installing a replacement part that improves the function of your vehicle. Making your car work better than before the repair, the betterment would increase its overall value. While some betterment policies cover the entire cost of the new part, others may only pay for half, requiring some out-of-pocket expenses by the consumer.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Compare the Limits of Liability</h2>
                        <p style={{ lineHeight: pLineHeight }}>Auto warranties are similar to insurance policies in that they limit coverage costs on their plans. So, if the car repair cost exceeds the set amount, the owner must pay the remaining balance.</p>
                        <p style={{ lineHeight: pLineHeight }}>Contained in nearly every auto policy, limits of liability have a maximum payout, such as $15,000, or the cash value of your car, whichever is higher. It is important to research the limits paid out for claims and if the limit restarts after each claim or if it is aggregated over the lifetime of the policy.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
