import React from 'react'
import { Col, Grid, Row, Button, Modal } from 'antd';
import homeStyles from '../Home.module.css';

const { useState, useEffect } = React;

const { useBreakpoint } = Grid;

export default function CarWarrantyModal() {
    const [visible, setVisible] = useState(false);

    const closeModal = () => {
        setVisible(false);
    }


    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginBottom = '';
    let marginTop = '';
    let pFontSize = '';
    let pLineHeight = '';
    let h1FontSize = '';
    let h1LineHeight = '';
    let h1SubHeadingFontSize = '';
    let h1SubHeadingLineHeight = '';
    let h2FontSize = '';
    let h2LineHeight = '';
    let h3LineHeight = '';
    let backgroundHeight = '';

    if (xs) {
        pFontSize = '1.286rem';
        pLineHeight = '1.286rem';
        h1FontSize = '3.429rem';
        h1LineHeight = '3.429rem';
    }

    if (sm) {
        pFontSize = '1.286rem';
        pLineHeight = '1.286rem';
        h1FontSize = '3.429rem';
        h1LineHeight = '3.429rem';
    }

    if (md) {
        pFontSize = '1.286rem';
        pLineHeight = '1.286rem';
        h1FontSize = '3.429rem';
        h1LineHeight = '3.429rem';
    }

    if (lg) {
        pFontSize = '1.286rem';
        pLineHeight = '1.286rem';
        h1FontSize = '3.429rem';
        h1LineHeight = '3.429rem';
    }


    return (
        <>
            <Button onClick={() => setVisible(true)} type="link" className={homeStyles.modalLink}>What is an after market car warranty?</Button>

            <Modal
                centered
                open={visible}
                className='flex justify-center'
                closable={false}
                onCancel={() => closeModal()}
                destroyOnClose={true}
                width={1000}
                footer={[
                    <Button key="back" onClick={() => closeModal()}>
                        Close
                    </Button>
                ]}
            >
                <Row type="flex" justify="center" className='flex item-center justify-center' style={{ textAlign: 'center', padding: '40px 20px' }}>
                    <Col span={24} justify='center'>
                        <h1 style={{ fontSize: h1FontSize, lineHeight: h1LineHeight, color: '#606060', fontWeight: 600, marginBottom: '20px' }}>What is an after market car warranty?</h1>
                        <p style={{ fontSize: pFontSize, lineHeight: pLineHeight, color: '#606060', fontWeight: 300, textAlign: 'left' }}>
                            <strong>Vehicle Service Contracts (VSC) are commonly referred to as extended warranties by consumers and the media; however, VSCs provide contractual coverage after a manufacturer’s original warranty has expired for people who want to help control the expenses of unexpected breakdowns.</strong>
                        </p>
                        {/* <Button onClick={() => closeModal()} type="link" className={homeStyles.compareButton}>Learn More About VSCs</Button> */}
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
