import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function DifferenceBetweenCarWarrantyAndInsurance() {
    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }
    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>Car Warranty vs. Car Insurance: What's the Difference? | Warranty Reviews</title>
                <meta property="og:title" content="Car Warranty vs. Car Insurance: What's the Difference? | Warranty Reviews" />
                <meta name="description" content="Learn more about car warranties and car insurance policies, including the differences between what each covers, their duration, providers, and costs." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>Understanding the Differences <br />Between a Car Warranty and Automobile Insurance Policy</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>Car Warranty vs. Car Insurance: What's the Difference?</span></p></Col>
                </Row>
            </section>

            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>When purchasing a vehicle, there are two essential forms of coverage to consider: the car warranty and the auto insurance policy. While both are designed to provide protection and peace of mind, they serve different purposes and cover distinct aspects of vehicle ownership. In this blog post, we will explore the differences between a car warranty and an insurance policy, shedding light on what each covers, their duration, providers, and costs.</p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/WarrantyVsInsurance_WR.png'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>What Is an Extended Car Warranty?</h2>
                        <p style={{ lineHeight: pLineHeight }}>A <Link to={'/car-warranty-faq/are-extended-car-warranties-worth-it'}>manufacturer car warranty</Link> is a promise from the manufacturer, dealer, or third-party auto protection company to cover certain defects and repairs within a specified period of time. Car warranties can be limited, <Link to={'/car-warranty-faq/what-do-car-warranties-cover'}>covering only essential components</Link> like the engine and transmission, or comprehensive, extending to various parts of the vehicle.</p>
                        <p style={{ lineHeight: pLineHeight }}><Link to={'/car-warranty-faq/how-do-car-warranties-work'}>Car warranties</Link> ensure that any issues or defects caused by the manufacturer's design or installation will be fixed by the car warranty provider. Typically, car warranties fall into two categories: manufacturer auto warranties and extended auto warranties.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Types of Warranties</h3>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}><strong>Factory Warranty:</strong> Provided by the manufacturer, it covers repairs and replacements due to defects in materials or workmanship.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Extended Warranty:</strong> Also known as a Vehicle Service Contract, it offers additional protection beyond the factory warranty, covering repairs and replacements for a more extended period. Extended warranties can be purchased from the manufacturer or dealer or from third-party auto protection companies.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}><strong>What Is an Auto Insurance Policy?</strong></h2>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>An auto insurance policy is a contract between the vehicle owner and the insurance company. It provides financial protection against physical damage, bodily injury, and liability resulting from traffic collisions and other incidents. Unlike car warranties, insurance policies require regular premium payments and may have deductibles.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Types of Insurance Coverage</h3>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}><strong>Liability Insurance:</strong> Covers damages to others caused by the policyholder.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Collision Insurance:</strong> Pays for damage to the policyholder's car from a collision.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Comprehensive Insurance:</strong> Covers damage to the car from non-collision events like theft, vandalism, or natural disasters.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Key Differences Between Car Warranty and Insurance Policy</h2>
                        <p style={{ lineHeight: pLineHeight }}>While both car warranties and car insurance give owners protection from unexpected issues, they differ in coverage, duration, provider, and cost.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Car Warranty Facts:</h3>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Warranties cover defects and repairs related to manufacturing or workmanship. Extended car warranties are an extension of a manufacturer's warranty and pick up where the existing warranty drops off.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Warranties have a fixed duration, often tied to the vehicle's age or mileage.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Warranties are provided by the manufacturer or dealer. Extended car warranties are sold by a third-party auto protection company.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>The cost of manufacturer car warranties is included in the vehicle's purchase price. Extended car warranties are paid either monthly or as a one-time fee.</p></li>
                        </ul>
                    </Col>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Car Insurance Facts:</h3>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}>Covers accidents, liabilities, and damages to the vehicle or others.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Ongoing duration with regular premium payments.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Car insurance policies are provided by insurance companies.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Cost requires separate, regular payments.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}><strong>Choosing a Car Warranty Company</strong></h2>
                        <p style={{ lineHeight: pLineHeight }}>A car warranty and insurance policy are vital components of vehicle ownership, providing protection and peace of mind in different ways. While a warranty focuses on mechanical breakdowns and defects, insurance covers a broader range of incidents and liabilities. By understanding the differences and carefully evaluating your needs, you can choose the right warranty and insurance coverage for your vehicle.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Protection on the road is not just about having a car warranty or car insurance. It's about selecting the right coverage that aligns with your needs and provides the peace of mind you deserve. If you choose to continue your automobile's warranty coverage through an <Link to='/car-warranty-faq/how-do-car-warranties-work'>extended car warranty</Link>, keep the following considerations in mind when selecting your vehicle service contract:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}><strong>Evaluate Your Needs:</strong> Consider your driving habits, the age of the vehicle, and your financial situation to determine the level of coverage you need.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Read the Fine Print:</strong> Carefully review the terms and conditions of the warranty to understand what is covered and what is not.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Compare Providers:</strong> Shop around and compare different warranty providers to find the best deal that suits your needs.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Consider the Long Term:</strong> Think about the long-term implications, such as your car's resale value if you plan to sell the vehicle. Extended warranties may add value to the sale.</p></li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col offset={2} span={20}>
                        <p style={{ lineHeight: pLineHeight }}>Warranty Reviews can help you save time researching companies. We provide you with the resources to browse reviews from actual customers and learn the car warranty advantages of each company. Compare warranty companies to select the best plan for you.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
