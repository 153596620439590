import React from 'react';
import MetaTags from 'react-meta-tags';
import { Col, Grid, Layout, Row, Button, Image, Divider } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import resourceFaqStyles from './CarWarrantyFaq.module.css';
import NavBar from '../Shared/NavBar';
import { Link, LinkProps, useHistory } from 'react-router-dom';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function WhenCarWarrantiesExpire() {
    let history = useHistory();

    const reviewPage = () => {
        history.push(`/car-warranty-reviews`)
    }
    const { xs, sm, md, lg } = useBreakpoint();
    let fontSize = ''
    let lineHeight = '';
    let marginTop = '';
    let pLineHeight = '';
    let h2LineHeight = '';

    if (xs) {
        fontSize = '2.188rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (sm) {
        fontSize = '2.488rem';
        lineHeight = '3rem';
        marginTop = '20px';
        pLineHeight = '1.5rem';
        h2LineHeight = '2.7rem';
    }

    if (md) {
        fontSize = '2.5rem';
        lineHeight = '3rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    if (lg) {
        fontSize = '3.125rem';
        lineHeight = '3.125rem';
        marginTop = '150px';
        pLineHeight = '2.5rem';
        h2LineHeight = '2.7rem';
    }

    return (
        <Layout className="resourceFaqStyles.layoutStyle">
            <MetaTags>
                <title>What Happens When Your Manufacturer Car Warranty Expires | Warranty Reviews</title>
                <meta property="og:title" content="What Happens When Your Manufacturer Car Warranty Expires | Warranty Reviews" />
                <meta name="description" content="If you have a factory car warranty, it is important to know what happens when it expires and how to replace the coverage you no longer have." />
            </MetaTags>
            <section className={resourceFaqStyles.headerStyle}>
                <Row justify="center" align="middle">
                    <Col><h1 style={{ fontSize: fontSize, lineHeight: lineHeight }}>What Happens If Your Car Warranty Expires</h1></Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col span={3}><Divider className={resourceFaqStyles.dividerStyle} /></Col>
                </Row>
                <Row justify="center" align="middle" className={resourceFaqStyles.subHeaderStyle}>
                    <Col><p style={{ lineHeight: pLineHeight }}><Link to="/car-warranty-faq">Resources &amp; FAQs</Link> &#x276F; <span className={resourceFaqStyles.resourceTitle}>What Happens If Your Car Warranty Expires</span></p></Col>
                </Row>
            </section>

            <Content className={resourceFaqStyles.contentStyle}>
                <Row className={resourceFaqStyles.rowSpacingTop}>
                    <Col lg={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                        <p style={{ lineHeight: pLineHeight }}>The moment your manufacturer car warranty expires marks a pivotal juncture in your automotive journey. Up until this point, the manufacturer's warranty has probably acted as your financial safety net, covering most major repair and maintenance costs, including the engine, transmission, electrical system, brakes, steering, air conditioning, and more.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Manufacturer, or factory, warranties generally cover you for 3 to 5 years or up to a specific mileage. Extended warranties, however, are <Link to='/car-warranty-faq/extended-car-warranty-benefits'>additional protection plans</Link> that can be purchased to extend your car's coverage beyond the original factory warranty period. But what happens when a car warranty expires?</p>
                    </Col>
                    <Col lg={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }}>
                        <Image preview={false} src={'/assets/images/What-happens-when-your-manufacturer-car-warranty-expires.png'} />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Extended Warranties: Your Next Line of Defense</h2>
                        <p style={{ lineHeight: pLineHeight }}>Extended car warranties can be acquired from either the dealership or third-party providers. Dealership extended warranties offer a seamless customer experience, as you remain within the manufacturer's service ecosystem. Warranties from third-party providers often offer more flexibility in terms of repair locations and overall cost. With a third-party extended warranty, you can typically visit any licensed repair facility rather than being restricted to dealership service centers exclusively.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>The scope of coverage also varies widely among extended warranty options. Some are quite comprehensive, while others focus on powertrain components only. Be sure to <Link to='/car-warranty-reviews'>compare extended car warranty companies</Link> and read the fine print to understand what's covered and what's not. Also pay close attention to any deductibles, exclusions, and limitations.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Frequently Asked Questions About Car Warranty Expirations</h2>
                        <p style={{ lineHeight: pLineHeight }}>Navigating car warranties can be stressful, especially when you’re facing a warranty expiration and have lots of questions! Here are some FAQs about what to do when your car warranty is about to expire or has expired.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Do I get a notice when my car warranty expires?</h3>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>In most cases, car warranty providers will send out email, mail, or phone reminders as the warranty expiration date approaches. But don't rely solely on these notices. As the vehicle owner, you can set calendar reminders or note the car warranty expiration date in a prominent spot months in advance. Modern vehicle touchscreen systems may even offer built-in notifications for this purpose.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>How do I check when my factory car warranty expires?</h3>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Manufacturer car warranty expiration can vary depending on the type of warranty you purchased. If you did not mark the expiration date in your calendar, some places you can check for your warranty end date could be:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}><strong>Warranty documents</strong> that came with your vehicle. Look for the section that specifies the warranty coverage and its duration.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Manufacturer or dealership</strong> you purchased the vehicle from. You will need your vehicle’s VIN, Vehicle Identification Number, and they should be able to provide you with more information about the warranty coverage. If you purchased a pre-owned vehicle, there may have been <Link to='/car-warranty-faq/can-you-transfer-a-car-warranty'>a manufacturer or extended car warranty that transferred with the ownership</Link>.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>Do car warranties auto-renew?</h3>
                        <p style={{ lineHeight: pLineHeight }}>Generally speaking, manufacturer car warranties do not auto-renew. It is the car owner's responsibility to purchase an extended car warranty prior to expiration, if continued coverage is desired.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>Some extended car warranties do offer the possibility of renewal. However, specific terms and conditions apply, which can include mileage and age limitations. For example, an extended warranty provider may decline to renew if your vehicle surpasses a certain mileage threshold or reaches a defined age. Always read the fine print to understand the nuances of your coverage and any associated renewal costs.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>My car insurance is coming up for renewal. Does this mean my warranty is expiring too?</h3>
                        <p style={{ lineHeight: pLineHeight }}>A manufacturer or extended car warranty is not the same as car insurance. Car warranties cover defects and repairs related to manufacturing or workmanship. Car insurance covers liabilities, accidents, and damages to your vehicle or others' vehicles. Learn more about the <Link to='/car-warranty-faq/difference-between-car-warranty-and-insurance'>difference between car warranties and car insurance</Link>.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h3 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>What do I do when my car warranty is expiring?</h3>
                        <p style={{ lineHeight: pLineHeight }}>It's time to shop around! If you're considering an extended car warranty, here are some important things to consider:</p>
                        <ul>
                            <li><p style={{ lineHeight: pLineHeight }}><strong>Cost-Benefit Analysis:</strong> Carefully weigh the cost of the extended warranty against your potential future repair expenses based on your vehicle's make, model, and mileage. This will give you a clear picture of the value proposition.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Coverage Scope:</strong> Ensure that the extended warranty provides robust coverage for the components most likely to require repairs in your particular vehicle, based on repair trends and common issues.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Provider Reputation:</strong> Look for consistent feedback praising the provider's customer service, ease of claims processing, and overall responsiveness. A reputation for smooth experiences is pivotal.</p></li>
                            <li><p style={{ lineHeight: pLineHeight, marginTop: '10px' }}><strong>Customer Experiences:</strong> Third-party review sites allow customers to leave honest feedback, highlighting satisfaction rates, reliability, quality of service, and more. They can also help you flag any potential red flags with specific providers, facilitating an informed decision-making process.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <h2 className='mt-10 mb-5' style={{ lineHeight: h2LineHeight }}>What Lies Beyond the Warranty Horizon</h2>
                        <p style={{ lineHeight: pLineHeight }}>The expiration of your manufacturer car warranty represents a significant milestone, one that necessitates planning and forethought. Whether you decide to invest in an extended warranty or brave the road without one, understanding your options can substantially ease the journey beyond the warranty horizon.</p>
                        <p style={{ lineHeight: pLineHeight, marginTop: '10px' }}>By tracking key dates, exploring renewal possibilities, comparing providers, and leveraging third-party reviews, you can traverse this transition with confidence you’ll find the best car warranty company. While saying goodbye to your warranty signals the end of one chapter, it also kicks off an empowering new adventure in self-reliance and vehicle ownership.</p>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Button onClick={() => reviewPage()} className={resourceFaqStyles.compareButton}>Read Reviews</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
