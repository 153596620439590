import create from 'zustand';


export const useStore = create((set) => ({
    toggled: false,
    toggle: () => set((state) => ({ toggled: !state.toggled })),
}))

export const loginStore = create((set) => ({
    toggled: false,
    toggle: () => set((state) => ({ toggled: !state.toggled })),
}))

export const reviewStore = create((set) => ({
    toggled: false,
    toggle: () => set((state) => ({ toggled: !state.toggled })),
}))