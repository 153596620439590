import React from 'react'
import { Col, InputNumber, Row, Button, Form, Input, Modal, notification, Progress, Radio } from 'antd';
import getQuoteFormStyles from './GetQuoteForm.module.css';
import reqwest from "reqwest";
import { useHistory } from "react-router-dom";
import { useStore } from '../../context/Store';
import axios from 'axios'
import queryString from 'query-string';

const { useState, useEffect } = React;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        span: 20,
    },
};



export default function GetQuoteForm({ isCompanyCard }) {
    let history = useHistory();

    const [ip, setIP] = useState({});
    const [isLoadingMakes, setIsLoadingMakes] = useState(false);
    const [isLoadingModels, setIsLoadingModels] = useState(false);
    const [isLoadingYears, setIsLoadingYears] = useState(false);
    const [makes, setMakes] = useState([]);
    const [makesDisabled, setMakesDisabled] = useState(true);
    const [models, setModels] = useState([]);
    const [modelsDisabled, setModelsDisabled] = useState(true);
    const [quoteFormData, setQuoteFormData] = useState({
        year_of_car: '',
        current_mileage: '',
        make_of_car: '',
        model_of_car: '',
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        zip_code: '',
    });
    const [searchParams, setSearchParams] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [preferredMakeList, setPreferredMakeList] = useState([]);
    const [showCustomerInformation, setShowCustomerInformation] = useState(false);
    const [showFormCompletion, setShowFormCompletion] = useState(false);
    const [showPreferredMakes, setShowPreferredMakes] = useState(false);
    const [showVehicleMake, setShowVehicleMake] = useState(false);
    const [showVehicleMileage, setShowVehicleMileage] = useState(false);
    const [showVehicleModel, setShowVehicleModel] = useState(false);
    const [showVehicleYear, setShowVehicleYear] = useState(true);
    const [yearRange, setYearRange] = useState([]);
    const [yearsDisabled, setYearsDisabled] = useState(true);
    const [visible, setVisible] = useState(false);

    const [form] = Form.useForm();

    const backToVehicleMake = () => {
        setShowVehicleModel(false);
        setShowPreferredMakes(true);
        setShowVehicleMake(false);
    }

    const backToVehicleMileage = () => {
        setShowPreferredMakes(false);
        setShowVehicleMake(false);
        setShowVehicleMileage(true);
    }

    const backToVehicleModel = () => {
        setShowCustomerInformation(false);
        setShowVehicleModel(true);
    }
    const backToVehicleYear = () => {
        setShowVehicleMileage(false);
        setShowVehicleYear(true);
    }

    const closeModal = () => {
        setQuoteFormData({
            year_of_car: '',
            current_mileage: '',
            make_of_car: '',
            model_of_car: '',
            first_name: '',
            last_name: '',
            email_address: '',
            phone_number: '',
            zip_code: '',
        });
        setShowPreferredMakes(false);
        setShowVehicleYear(true);
        setShowVehicleMake(false);
        setShowVehicleMileage(false);
        setShowVehicleModel(false);
        form.resetFields();
        setVisible(false);
        setCompletionPercentage(0);
    }

    const createRateQuote = async (form_data, searchParams, ip) => {

        let new_form_data = { rate_quote: Object.assign({}, form_data['rate_quote'], searchParams) }
        new_form_data = { rate_quote: Object.assign({}, new_form_data['rate_quote'], ip) }

        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/rate_quotes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(new_form_data),
        })
            .then((response) => {
                if (response.ok) {
                    setCompletionPercentage(100);
                    setShowVehicleYear(true);
                    setShowCustomerInformation(false);
                    setShowFormCompletion(false);
                    form.resetFields();
                    closeModal();
                    history.push("/quote-request-thank-you");
                    return response.json();
                } else {
                    return response.text().then(text => { throw new Error(text); });
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Failed to Create Quote',
                    description: error,
                })
            })
    }

    const getYearRange = async (params = {}) => {
        setIsLoadingYears(true);

        try {
            const response = await reqwest({
                url: `${process.env.REACT_APP_API_URL}/api/v1/rate_quotes/year_range`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                type: 'json'
            });
            setYearRange([]);

            response.map(
                (item) => {
                    setYearRange(yearRange => [...yearRange, {
                        label: item,
                        value: item
                    }])
                }
            )
            setIsLoadingYears(false);
            setYearsDisabled(false);
        } catch (error) {
            setIsLoadingYears(false);
            notification.error({
                message: 'Error',
                description: JSON.parse(error.response).error_message
            });
        }
    };

    const mileageRange = [
        { label: 'Less than 100K', value: '99999' },
        { label: '100-140K', value: '100000' },
        { label: '140-200K', value: '140000' },
        { label: 'More than 200K', value: '200000' },
        { label: "I don't know", value: '0' }
    ];

    //creating function to load ip address from the API
    const getIpAddressOfVisitor = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP({
            ipAddress: res.data.IPv4,
            ipLocation: `${res.data.city}, ${res.data.state}, ${res.data.country_name}`
        })
    }

    const getMakesForYear = async (params = {}) => {
        setIsLoadingMakes(true);
        setSelectedYear(params.year);
        setModels([]);
        setModelsDisabled(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/rate_quotes/makes_for_year/${params.year}`, {
            method: 'GET',
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then(response => response.json())
            .then(response => {
                setMakes([]);
                setPreferredMakeList([]);
                response.makes_for_year.data.map(
                    (item) => {
                        setMakes(makes => [...makes, {
                            label: item.attributes.make_display,
                            value: item.attributes.make_id
                        }])
                    }
                )
                response.makes_for_year.data.slice(0, 6).map(
                    (item) => {
                        setPreferredMakeList(preferredMakeList => [...preferredMakeList, {
                            label: item.attributes.make_display,
                            value: item.attributes.make_id
                        }])
                    }
                )
                setIsLoadingMakes(false);
                setMakesDisabled(false);
                setShowVehicleYear(false);
                setShowVehicleMileage(true);
                setCompletionPercentage(20);
            })
            .catch(error => {
                setIsLoadingMakes(false);
                console.log(error);
            }   // parses response to JSON
            );
    };

    const getModelsForMakeAndYear = async (params = {}) => {
        setIsLoadingModels(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/rate_quotes/models_for_make_and_year/${selectedYear}/${params.make}`, {
            method: 'GET',
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then(response => response.json())
            .then(response => {
                setModels([]);

                response.models_for_make_and_year.data.map(
                    (item) => {
                        setModels(models => [...models, {
                            label: item.attributes.name,
                            value: item.attributes.name
                        }])
                    }
                )
                setIsLoadingModels(false);
                setModelsDisabled(false);
                setShowPreferredMakes(false);
                setShowVehicleMake(false);
                setShowVehicleModel(true);
                setCompletionPercentage(60);
            })
            .catch(error => {
                setIsLoadingModels(false);
                console.log(error);
            }   // parses response to JSON
            );
    };

    const getSearchParams = () => {
        let params = queryString.parse(location.search);
        setSearchParams(params);
    }

    const missingCustomerInfo = () => {
        if ((quoteFormData.first_name.length > 0) &&
            (quoteFormData.last_name.length > 0) &&
            (quoteFormData.email_address.length > 0) &&
            (quoteFormData.phone_number.length > 0) &&
            (quoteFormData.zip_code > 0)) {
            return false
        } else {
            return true
        }
    }

    const missingVehicleModel = () => {
        if (!quoteFormData.model_of_car.length > 0) {
            return true
        } else {
            return false
        }
    }

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setQuoteFormData({ ...quoteFormData, [field.name[0]]: field.value })
        })
    }

    const onFinish = (values) => {
        createRateQuote({
            rate_quote: quoteFormData
        }, searchParams, ip);

    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Create Quote',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onReset = () => {
        form.resetFields();
    };

    const showCompleteMakeList = () => {
        setShowPreferredMakes(false);
        setShowVehicleMake(true);
    }

    useEffect(() => {
        getIpAddressOfVisitor();
        getSearchParams();
        getYearRange({});
    }, []);

    const validatePhoneNumber = (rule, value, callback) => {
        if (value && !/^\d{10}$/.test(value)) {
            callback('Phone number must be 10 digits');
        } else {
            callback();
        }
    }

    const validateZipCode = (rule, value, callback) => {
        if (value && !/^\d{5}$/.test(value)) {
            callback('Zip code must be 5 digits');
        } else {
            callback();
        }
    }

    return (
        <>
            {isCompanyCard &&
                <Button onClick={() => setVisible(true)} style={{ width: 'auto', height: '45px', fontSize: '1.15rem', margin: '15px auto 0', backgroundColor: '#C42E1C' }} className="px-3 py-2 flex items-center text-xs font-bold text-white hover:opacity-75" type='danger'><span style={{ margin: '0 auto' }}>Get WR Partner Quote</span></Button>
            }
            {!isCompanyCard &&
                <Button style={{ backgroundColor: '#C42E1C' }} onClick={() => setVisible(true)} className="px-3 py-2 ml-3 flex items-center text-xs font-bold text-white hover:opacity-75" type='danger'><span style={{ margin: '0 auto' }}>Get WR Partner Quote</span></Button>
            }
            <Modal
                centered
                open={visible}
                className='flex justify-center'
                closable={false}
                onCancel={() => closeModal()}
                destroyOnClose={true}
                width={1000}
                footer={[
                    <Button key="back" onClick={() => closeModal()}>
                        Close
                    </Button>
                ]}
            >
                <Row id='rate-quote-form' type="flex" justify="center" className='flex item-center justify-center'>
                    <Progress percent={completionPercentage} status="active" />
                    <Form
                        {...layout}
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onFieldsChange={onFieldsChange}
                    >
                        <div className="">
                            {showVehicleYear && (
                                <div id='vehicle-year'>
                                    <Row className='flex item-center justify-center'>
                                        <Col span={8}>
                                            <h4 className='text-2xl font-bold text-center mb-0 mt-3'>Select Your Vehicle Year</h4>
                                            <p className='text-base text-center'>Don't know the year? Select your best guess.</p>
                                        </Col>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-3'>
                                        <Form.Item
                                            name="year_of_car"
                                            className=''
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your year of vehicle!',
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                options={yearRange}
                                                span={8}
                                                className=''
                                                onChange={(e) => {
                                                    setSelectedYear(e.target.value);
                                                    getMakesForYear({ year: e.target.value });
                                                }}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </Form.Item>
                                    </Row>
                                    <Row className='flex item-center justify-center'>

                                    </Row>
                                </div>
                            )}
                            {showVehicleMileage && (
                                <div id='vehicle-mileage'>
                                    <Row className='flex item-center justify-center'>
                                        <Col span={16}>
                                            <h4 className='text-2xl font-bold text-center mb-0 mt-3'>Roughly, how many miles are on the vehicle?</h4>
                                        </Col>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-3'>
                                        <Form.Item
                                            name="current_mileage"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your current mileage!',
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                options={mileageRange}
                                                optionType="button"
                                                buttonStyle="solid"
                                                onChange={(e) => {
                                                    setShowVehicleMileage(false);
                                                    setShowPreferredMakes(true);
                                                    setCompletionPercentage(40);
                                                }}
                                            />
                                        </Form.Item>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>

                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>
                                        <Button onClick={() => { backToVehicleYear() }} type="text">
                                            &lt; Back
                                        </Button>
                                    </Row>
                                </div>
                            )}
                            {showPreferredMakes && (
                                <div id='vehicle-make'>
                                    <Row type="flex" justify="center" className=''>
                                        <Col span={8}>
                                            <h4 className='text-2xl font-bold text-center mb-0 mt-3'>Select Your Vehicle Make</h4>
                                        </Col>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-3'>
                                        <Form.Item
                                            name="make_of_car"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your make of vehicle!',
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                options={preferredMakeList}
                                                span={8}
                                                optionType="button"
                                                buttonStyle="solid"
                                                loading={isLoadingMakes}
                                                disabled={makesDisabled}
                                                onChange={(e) => { getModelsForMakeAndYear({ make: e.target.value }); }}
                                            />
                                        </Form.Item>
                                    </Row>
                                    <Row type="flex" justify="center" className='flex item-center justify-center'>
                                        <Button onClick={() => { showCompleteMakeList() }} type="default">
                                            Show Complete List
                                        </Button>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>

                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>
                                        <Button onClick={() => { backToVehicleMileage() }} type="text">
                                            &lt; Back
                                        </Button>
                                    </Row>
                                </div>
                            )}
                            {showVehicleMake && (
                                <div id='vehicle-make'>
                                    <Row type="flex" justify="center" className=''>
                                        <Col span={8}>
                                            <h4 className='text-2xl font-bold text-center mb-0 mt-3'>Select Your Vehicle Make</h4>
                                        </Col>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-3'>
                                        <Form.Item
                                            name="make_of_car"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your make of vehicle!',
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                options={makes}
                                                span={8}
                                                optionType="button"
                                                buttonStyle="solid"
                                                loading={isLoadingMakes}
                                                disabled={makesDisabled}
                                                onChange={(e) => { getModelsForMakeAndYear({ make: e.target.value }); }}
                                            />
                                        </Form.Item>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>

                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>
                                        <Button onClick={() => { backToVehicleMileage() }} type="text">
                                            &lt; Back
                                        </Button>
                                    </Row>
                                </div>
                            )}
                            {showVehicleModel && (
                                <div id='vehicle-model'>
                                    <Row type="flex" justify="center" className=''>
                                        <Col span={8}>
                                            <h4 className='text-2xl font-bold text-center mb-0 mt-3'>Select Your Vehicle Model</h4>
                                        </Col>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-3'>
                                        <Form.Item
                                            name="model_of_car"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your model of vehicle!',
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                options={models}
                                                span={8}
                                                optionType="button"
                                                buttonStyle="solid"
                                                disabled={modelsDisabled}
                                                loading={isLoadingModels}
                                                onChange={(e) => {
                                                    setShowVehicleModel(false);
                                                    setShowCustomerInformation(true);
                                                    setCompletionPercentage(80);
                                                }}
                                            />
                                        </Form.Item>
                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>

                                    </Row>
                                    <Row className='flex item-center justify-center mt-5'>
                                        <Button onClick={() => { backToVehicleMake() }} type="text">
                                            &lt; Back
                                        </Button>
                                    </Row>
                                </div>
                            )}
                            {showCustomerInformation && (
                                <div id='customer-information'>
                                    <Row type="flex" justify="center" className=''>
                                        <Col span={8}>
                                            <h4 className='text-2xl font-bold text-center mb-0 mt-3'>Tell Us About You</h4>
                                        </Col>
                                    </Row>
                                    <div className='flex flex-col item-center justify-items-center mt-3'>
                                        <Form.Item>
                                            <Form.Item
                                                name={"first_name"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your first name!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <label>First Name</label>
                                        </Form.Item>
                                        <Form.Item>
                                            <Form.Item
                                                name={"last_name"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your last name!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <label>Last Name</label>
                                        </Form.Item>
                                        <Form.Item>
                                            <Form.Item
                                                name={"phone_number"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your phone number!',
                                                    },
                                                    {
                                                        validator: validatePhoneNumber
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <label>Phone Number</label>
                                        </Form.Item>
                                        <Form.Item>
                                            <Form.Item
                                                name={"email_address"}
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        required: true,
                                                        message: 'Please enter your email address!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <label>Email Address</label>
                                        </Form.Item>
                                        <Form.Item>
                                            <Form.Item
                                                name={"zip_code"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your zip code!'
                                                    },
                                                    {
                                                        type: 'integer',
                                                        message: 'Zip Code can only include numbers.',
                                                    },
                                                    {
                                                        validator: validateZipCode
                                                    },
                                                ]}
                                            >
                                                <InputNumber />
                                            </Form.Item>
                                            <label>Zip Code</label>
                                        </Form.Item>
                                    </div>
                                    <Row className='flex item-center justify-center'>
                                        <Form.Item {...tailLayout}>
                                            <Button
                                                style={{ backgroundColor: '#C42E1C' }}
                                                type="danger"
                                                htmlType="submit"
                                                disabled={missingCustomerInfo()}
                                                className="px-3 py-2 ml-3 flex items-center text-xs font-bold text-white hover:opacity-75 rounded-md"
                                            >
                                                Continue to Get Your Free Quote
                                            </Button>
                                        </Form.Item>
                                    </Row>
                                    <Row className='flex item-center justify-center'>
                                        <Col span={10}>
                                            <p>By clicking "CONTINUE TO GET YOUR FREE QUOTE" and providing my electronic signature, I grant consent for WarrantyReviews.com and its partners to call, text or email me with additional information and offers. I understand that communications may be sent using an automated system and that I am not required to click the above button as a condition of purchase.</p>
                                        </Col>
                                    </Row>
                                    <Row className='flex item-center justify-center'>
                                        <Button onClick={() => { backToVehicleModel() }} type="text">
                                            &lt; Back
                                        </Button>
                                    </Row>
                                </div>
                            )}
                            {showFormCompletion && (
                                <div id='form-completion'>
                                    <Row className='flex item-center justify-center' style={{ textAlign: 'center', justifyContent: 'center' }}>
                                        <Col span={14}>
                                            <h4 className='text-4xl font-bold text-center mb-0'>Thank You!</h4>
                                            <span className='text-center text-lg font-light'>
                                                Your car warranty quote request has been submitted. A WarrantyReview.com partner will call or email you with additional information.
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    </Form>
                </Row>
            </Modal>
        </>
    )
}
