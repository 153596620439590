import { Route, Redirect } from 'react-router-dom';
import useToken from '../useToken';


export { PrivateRoute };

function PrivateRoute({ component: Component, ...rest }) {
    const { token, setToken } = useToken();

    return (
        <Route {...rest} render={props => {
            if (Object.values(token).every(o => o === null)) {
                // not logged in, so redirect to the login page with the return url
                console.log('not logged in, redirecting to login page');
                return <Redirect to={{ pathname: '/', state: { from: props.location, message: 'You must be loggeed in before continuing.', showLogin: true } }} />
            }
            // logged in so return component
            return <Component {...props} />
        }} />
    );
}